<template>
  <div>
    <!-- <header> -->
    <!-- 导航栏-->
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar" id="navbar">
      <div :class="isMobile ? 'phoneHeader' : 'navContainer'">
        <div class="navigationTitle">
          <div style="margin-top: 5px; width: 100%; text-align: center">
            <a class="titleItems" href="#">
              <h1 style="margin-top: 2px;margin-bottom: 2.5px;line-height: normal;" :style="`${isMobile ? 'font-size: 14px' : 'font-size: 23px'}`">
                <span id="shadow">
                  电力工业电力设备及线路器材质量检验测试中心
                </span>
              </h1>
            </a>
          </div>
          <div v-if="!isMobile" style="margin-top: -5px; margin-bottom: 5px; width: 100%; text-align: center">
            <a class="titleItems" href="#"
              ><span id="shadow" style="font-size: 13px"
                >Power Industry Power Equipment and Transmission Material Inspection Test Center</span
              ></a
            >
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="collapse navbar-collapse"
          :style="{
            flexGrow: 0,
            paddingLeft: `${clientWidth > 1200 ? '120px' : '10px'} !important`,
          }"
          id="navbarContent"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-for="(item, index) in buttonList" :key="index">
              <div
                :id="item.type === '#' && isActive === item.src ? '' : 'shadow'"
                class="textItems"
                @click="jump(item.type, item.src)"
                :class="item.type === '#' && isActive === item.src ? 'isActive' : ''"
              >
                <div class="textChildTop">
                  <span>{{ item.zhName }}</span>
                </div>
                <div class="textChildDown">
                  <span>{{ item.enName }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div style="width: 100%" class="phoneHeaderButton" v-else>
          <div class="phone-item" v-for="(item, index) in buttonList" :key="index">
            <div
              @click="jump(item.type, item.src)"
              :class="item.type === '#' && isActive === item.src ? 'isActive' : ''"
            >
              <div id="phone-items">
                <span>{{ item.zhName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- </header> -->
  </div>
</template>
<script>
import router from '../../router'
export default {
  props: ['isActive'],
  data() {
    return {
      buttonList: [
        {
          zhName: '首页',
          enName: 'Home',
          type: '#',
          src: '#',
        },
        {
          zhName: '关于我们',
          enName: 'About us',
          type: '#',
          src: '#about',
        },
        {
          zhName: '下载专区',
          enName: 'Download',
          type: 'router',
          src: 'Download',
        },
        {
          zhName: '证书查询',
          enName: 'Cert',
          type: 'router',
          src: 'Cert',
        },
        {
          zhName: '报告查询',
          enName: 'Report',
          type: 'router',
          src: 'Report',
        },
        {
          zhName: '在线委托',
          enName: 'Entrust',
          type: 'router',
          src: 'Entrust',
        },
        {
          zhName: '招聘信息',
          enName: 'Jobs',
          type: 'router',
          src: 'Jobs',
        },
        {
          zhName: '联系我们',
          enName: 'Contact',
          type: '#',
          src: '#contact',
        },
      ],
    }
  },
  methods: {
    jump(type, src) {
      switch (type) {
        case '#':
          this.handleJump(src)
          break
        case 'router':
          this.handleJumpRouter(src)
          break
      }
    },
    handleJump(href) {
      const eleLink = document.createElement('a')
      // eleLink.download = 'logo'
      // eleLink.style.display = href
      eleLink.href = href
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    // 跳转路由
    handleJumpRouter(routerStr) {
      // 特殊处理，重新渲染首页头
      this.$emit('handleJumpRouter')
      if (routerStr) {
        router.push(routerStr)
      }
    },
  },
  computed: {
    // 浏览器宽度
    clientWidth() {
      return Number(this.$store.state.clientWidth)
    },
    // 浏览器高度
    clientHeight() {
      return Number(this.$store.state.clientHeight)
    },
    // 是否为手机端
    isMobile() {
      return this.$store.state.isMobile
    },
  },
}
</script>
<style scoped>
#shadow {
  text-shadow: 0 0 5px black;
}
#shadow:hover {
  text-shadow: 0 0 0 black;
}
.textItems:hover span {
  color: black !important;
  font-weight: 500;
  transition: all 0.2s;
}
.navContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.phoneHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}
.phoneHeaderButton {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.phone-item {
  font-size: 12px;
  min-width: 60px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
#phone-items {
  color: #fff;
}
.isActive span {
  color: rgb(26, 10, 10) !important;
  font-weight: bold;
}
.isActive:hover span {
  font-weight: bold;
}
.nav-item {
  min-width: 70px;
}
</style>
