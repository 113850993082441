<template>
  <div class="aboutUs" id="aboutContainerID">
    <section class="py-5 grey lighten-4" id="about">
      <div class="container">
        <div class="wow fadeIn">
          <h2 class="text-center h1 pt-5 pb-3">关于我们</h2>
        </div>
        <div id="aboutContent" v-html="aboutContent"></div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aboutContent: '',
      // 暂留字符串
      oldTextContent: ''
    }
  },
  props: {
  },
  mounted() {
  }
}
</script>
<style scoped>
.imgContainer {
  width: 100%;
  height: 90%;
}
.container p {
  text-align: left;
  margin: 0 !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  padding-bottom: 1rem !important;
}
.fairContainer p {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  padding-bottom: 1rem !important;
  font-size: 20px;
  font-weight: 500;
}
.fairContainer {
  text-align: left;
}
.fairContainer #list {
  font-size: 18px;
  padding-right: 5rem !important;
  padding-left: 5rem !important;
  padding-bottom: 0 !important;
}
#fullimage {
  position: fixed;
  top: 0;
  left: 0;
  height: 100% !important;
}
</style>