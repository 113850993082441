<template>
  <vxe-modal style="z-index: 999999" size="mini" ref="modal" v-model="formVisible" :title="modalTitle" width="80%">
    <div style="width: 100%; position: relative">
      <div class="el-message-box__status el-icon-warning"></div>
      <div class="confirTooltipTitle">{{ confirTitle }}</div>
    </div>
    <div class="confirButtonContainer">
      <el-button size="mini" @click="formVisible = false">取消</el-button>
      <el-button size="mini" @click="handleConfir()" type="primary">确认</el-button>
    </div>
  </vxe-modal>
</template>
<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: "提示"
    },
    confirTitle: {
      type: String,
      default: "是否确认?"
    }
  },
  data() {
    return {
      formVisible: false
    }
  },
  methods: {
    // 点击确认
    handleConfir() {
      this.$emit('sureBackConfir')
      this.formVisible = false
    }
  }
}
</script>
<style lang='less' scoped>
.confirTooltipTitle {
  font-size: 14px !important;
  margin-left: 30px;
  word-break: break-word;
}
.confirButtonContainer {
  display: flex;
  justify-content: flex-end;
}
</style>