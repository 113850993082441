<template>
  <div class="HomeJobs">
    <HomeHeaderFixed isActive="Jobs" />
    <div class="jobsContainer">
      <div id="jobsContent"></div>
    </div>
    <HomeFooter />
  </div>
</template>
<script>
import jobsHTML from '../../assets/js/jobs'
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
export default {
  components: {
    HomeHeaderFixed, HomeFooter
  },
  data() {
    return {
      textContext: jobsHTML
    }
  },
  mounted() {
    const dom = document.getElementById('jobsContent')
    const re = new RegExp(/\<table /g)
    this.textContext = this.textContext.replace(re, '<table border=1')
    dom.innerHTML = this.textContext
  }
}
</script>
<style lang="less" scoped>
@pageBackground: #f5f5f5;
@titleHeight: 5rem;
@footerHeight: 66.61px;
@topMargin: 30px;
@reportSearchContainerHeight: 100px;
.HomeJobs {
  height: 100%;
  overflow: hidden;
}
.jobsContainer {
  // padding: @titleHeight 0 0 0;
  // padding: @titleHeight 150px 0 150px;
  margin-top: @titleHeight;
  height: calc(100% - @footerHeight - @titleHeight);
  overflow: hidden;
  #jobsContent {
    // margin-top: @titleHeight;
    height: calc(100%);
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px 10% 0 10%;
  }
}
</style>