import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./assets/css/font-awesome.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/mdb.css";
import "./assets/css/main.css";
import "./assets/css/element-ui.css"
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from 'vue-baidu-map'
import router from './router'
import "../src/plugins/element.js";
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import './axios/index'
// import axios from 'axios'

// Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost:5555'

Vue.use(VXETable)
Vue.use(BaiduMap, {
  ak: 'fGfTLXq38kVNYRlVSGyNFXhDH715HnqZ'
})
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");