<template>
  <div id="app">
    <div class="mask" :style="{ height: scrollerHeight }" v-show="showLoading" ref="mask" @dblclick="handleHide">
      <LoadingCSS class="mask_img"></LoadingCSS>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <Home msg="Home" /> -->
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="fullScreenCss" v-if="isFullScreen">
      <div id="fullScreenContainer" v-html="fullScreenHTML"></div>
      <!-- 全屏缩小按钮 -->
      <div class="shutFullScreen" @click="$store.commit('set_FULLSCREENFLAG', false)" v-show="isFullScreen">
        <i class="vxe-icon--zoomout" style="font-size: 20px; color: #666666"></i>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
// import Home from "./views/Home/Home.vue";
import LoadingCSS from './components/loading.vue'
export default {
  name: "App",
  components: {
    // HelloWorld,
    // Home,
    LoadingCSS
  },
  mounted() {
    const that = this
    // 进入界面默认为初始路由home
    this.$router.push({
      name: 'Home',
      params: { sectionPath: '#pageTop' }
    }
    )
    // 禁用后退，会有闪烁,新增加的路由，还是可后退
    window.onpopstate = () => {
      history.go(1)
    }
    // 监听浏览器大小
    window.onresize = function () {
      const clientHeight = `${document.documentElement.clientHeight}`
      const clientWidth = `${document.documentElement.clientWidth}`
      const clientSize = {
        clientWidth,
        clientHeight
      }
      that.$store.commit('set_clientHeight', clientHeight)
      that.$store.commit('set_clientWidth', clientWidth)
      that.$store.commit('set_clientSize', clientSize)
    }
    const clientHeight = `${document.documentElement.clientHeight}`
    that.$store.commit('set_clientHeight', clientHeight)
    const clientWidth = `${document.documentElement.clientWidth}`
    that.$store.commit('set_clientWidth', clientWidth)

    this.$refs.mask.style.height = this.$store.state.clientHeight + 'px'
    // 判断是否为手机端
    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    that.$store.commit('set_isMobile', isMobile && isMobile.length > 0)
    // 如果使用横屏提示
    if (isMobile && isMobile.length > 0 && clientHeight < clientWidth) {
      this.$message.warning('检测到您正在用手机端访问, 建议您使用竖屏浏览体验更佳!')
    }
  },
  methods: {
    handleHide() {
      // this.$store.dispatch('resetloader')
    }
  },
  computed: {
    showLoading() {
      const showLoading = this.$store.state.showLoading
      return showLoading
    },
    // 滚动区高度
    scrollerHeight: function () {
      return this.$store.state.clientHeight + 'px'
    },
    // 是否全屏
    isFullScreen() {
      return this.$store.state.isFullScreen
    },
    // 全屏内容
    fullScreenHTML() {
      return this.$store.state.fullScreenHTML
    }
  }
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
/* html::-webkit-scrollbar {
  width: 4px;
}
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #27a59f;
  background: #27a59f;
}
html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgb(182, 181, 181);
  border-radius: 0;
  background: rgb(182, 181, 181);
} */
#app {
  width: 100%;
  height: 100%;
}
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: rgba(255, 255, 255, 0.1); */
  z-index: 999999;
  color: white;
}
.mask_img {
  z-index: 999999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.fullScreenCss {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100% !important;
  height: 100% !important;
  z-index: 100;
  background: #fff;
  overflow: auto;
}
.shutFullScreen {
  z-index: 101;
  position: fixed;
  right: 30px;
  top: 10px;
  width: 50px;
  height: 50px;
  border: #666666 solid 1px;
  opacity: 0.5;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shutFullScreen:hover {
  opacity: 0.8;
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
</style>
