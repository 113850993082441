import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import {
  MessageBox
} from 'element-ui'

let {
  main
} = WebApiBaseUrl

// 服务端设置基础URL
axios.defaults.baseURL = main
Vue.prototype.$http = axios

/**
 * 是否显示请求超时
 * @returns {boolean}
 */
function isShowTimeOut() {
  if (timeOutDate !== 0) {
    let nowTime = new Date().getTime()
    let willExpired = (nowTime - timeOutDate) / 1000 > 10
    return willExpired
  }
  return false
}


// http request 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.headers.loading === undefined || config.headers.loading === true) {
      store.dispatch('showloader')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
// 设置全局的请求次数，请求的间隙
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000
axios.interceptors.response.use(
  function (response) {
    store.dispatch('hideloader')
    if (response && response.config && response.config.headers) {
      if (response.data && response.data.type === 'text/plain') {
        var reader = new FileReader()
        reader.readAsText(response.data, 'utf-8')
        reader.onload = function (e) {
          if (reader.result !== '') {
            try {
              const result = JSON.parse(reader.result)
              MessageBox.alert(result.errorMessage, '提示', {
                type: 'error',
                showClose: false,
                confirmButtonText: '确定'
              })
            } catch (e) {
              MessageBox.alert(reader.result, '提示', {
                type: 'error',
                showClose: false,
                confirmButtonText: '确定'
              })
            }
          }
        }
        response.data = new Blob([''], {
          type: 'text/plain'
        })
        return response
      } else {
        return response
      }
    }
  },
  function axiosRetryInterceptor(err) {
    store.dispatch('hideloader')
    if (err.message.indexOf('timeout') !== -1) {
      const config = err.config
      let loading = false
      if (config.headers.loading === undefined || config.headers.loading === true) {
        loading = true
      }
      if (timeOutDate === 0 || isShowTimeOut() || loading) {
        // showTimeOut = true
        timeOutDate = new Date().getTime()
        // MessageBox({ message: '您的请求已超时', title: '提示' })
        MessageBox.alert('您的请求已超时', '提示', {
          showClose: false,
          confirmButtonText: '确定',
          callback: () => {
            timeOutDate = 0
            // showTimeOut = false
          }
        })
      }
      // MessageBox({ message: '请求超时', type: 'error', title: '提示' })
      return Promise.reject(err)
      // Promise.reject(err)
    } else {
      var config = err.config
      // If config does not exist or the retry option is not set, reject
      if (!config || !config.retry) return Promise.reject(err)

      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0

      // Check if we've maxed out the total number of retries
      if (config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(err)
      }

      // Increase the retry count
      config.__retryCount += 1

      // Create new promise to handle exponential backoff
      var backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve()
        }, config.retryDelay || 1)
      })

      // Return the promise in which recalls axios to retry the request
      return backoff.then(function () {
        return axios(config)
      })
    }
  }
)
// 设置请求超时时间
axios.defaults.timeout = 60000
Vue.config.productionTip = false