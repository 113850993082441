<template>
  <div class="HomeCert">
    <HomeHeaderFixed isActive="Cert" />
    <div class="certContainer">
      <div class="certSearchContainer" v-if="!isMobile">
        <div id="searchForm">
          <el-form @keyup.enter.native="handleSearch()" label-position="top" :model="form" :rules="rules">
            <el-row>
              <el-col :span="8">
                <el-form-item label="证书类型">
                  <el-select v-model="form.productname" clearable placeholder="请选择">
                    <el-option
                      v-for="(item, index) of comBoxItems.productname"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证书编号">
                  <el-input v-model="form.reportnum" clearable placeholder="输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="证书日期">
                  <el-date-picker
                    ref="certDatePicker"
                    @change="certDateChange"
                    @input.native="inputcertDate"
                    v-model="form.reportdate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="输入"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div id="searchButton">
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">查找</el-button>
        </div>
      </div>
      <div class="phoneCertSearchContainer" v-else>
        <div id="phoneSearchForm">
          <el-form @keyup.enter.native="handleSearch()" size="mini" :model="form" :rules="rules">
            <el-row>
              <el-col :span="24">
                <el-form-item label="证书类型">
                  <el-select v-model="form.productname" clearable placeholder="请选择">
                    <el-option
                      v-for="(item, index) of comBoxItems.productname"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="证书编号">
                  <el-input v-model="form.reportnum" clearable placeholder="输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="证书日期">
                  <el-date-picker
                    ref="certDatePicker"
                    @change="certDateChange"
                    @input.native="inputcertDate"
                    v-model="form.reportdate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="输入"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div id="phoneSearchButton">
          <el-button type="primary" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <div class="certResultContainer">
        <div id="nullData" v-if="!ifSearch">
          <img src="../../assets/nullData.png" alt="暂无内容" />
        </div>
        <div class="certFormContainer" v-else>
          <el-form :model="resultForm" v-if="!isMobile">
            <el-row :gutter="10">
              <el-col :span="resultForm.filePath ? 16 : 20">
                <el-form-item label="证书编号">
                  <el-input v-model="resultForm.certnum" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="resultForm.filePath" :span="4">
                <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 40px">
                  <el-button type="primary" @click="handleShowPDF">查看报告文件</el-button>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="20">
                <el-form-item label="企业名称">
                  <el-input v-model="resultForm.factoryname" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="this.resultType !== '环形混凝土电杆'">
              <el-col :span="20">
                <el-form-item label="产品名称">
                  <el-input v-model="resultForm.productname" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="this.resultType !== '环形混凝土电杆'">
              <el-col :span="20">
                <el-form-item label="电压等级">
                  <el-input v-model="resultForm.kv" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item label="发证日期">
                  <el-date-picker
                    v-model="resultForm.issuedate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="自动获取"
                    readonly
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="有效期">
                  <el-date-picker
                    v-model="resultForm.validtime"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="自动获取"
                    readonly
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="this.resultType === '环形混凝土电杆'">
              <el-col :span="20">
                <el-table :data="resultForm.detailList" border :height="tableHeight">
                  <el-table-column prop="spec" label="规格型号" align="center" header-align="center"> </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-form>
          <div class="phoneSearchFormContainer" v-else>
            <el-row>
              <el-col :span="24"> 证书编号： {{ resultForm.certnum }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 企业名称： {{ resultForm.factoryname }} </el-col>
            </el-row>
            <el-row v-if="this.resultType !== '环形混凝土电杆'">
              <el-col :span="24"> 产品名称： {{ resultForm.productname }} </el-col>
            </el-row>
            <el-row v-if="this.resultType !== '环形混凝土电杆'">
              <el-col :span="24"> 电压等级： {{ resultForm.kv }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 发证日期： {{ this.dateFormat('yyyy-MM-dd', resultForm.issuedate) }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 有效期： {{ this.dateFormat('yyyy-MM-dd', resultForm.validtime) }} </el-col>
            </el-row>
            <el-row>
              <el-col v-if="resultForm.filePath" :span="24">
                <el-button type="primary" size="mini" style="width: 100%" @click="handleShowPDF"
                  >查看报告文件</el-button
                >
              </el-col>
            </el-row>
            <el-row v-if="this.resultType === '环形混凝土电杆'">
              <el-col :span="24">
                <el-table :data="resultForm.detailList" border>
                  <el-table-column prop="spec" label="规格型号" align="center" header-align="center"> </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
    <!-- <PdfPreview ref="PdfPreview" :fromPDFSrc="PDFSrc" /> -->
    <PdfPreview
      ref="PdfPreview"
      :filePath="resultForm.filePath"
      :reportOrCertIsChange="reportOrCertIsChange"
      @handleGetBase64="handleGetBase64()"
    />
  </div>
</template>
<script>
// let { upload: uploadURL } = WebApiBaseUrl
import PdfPreview from '../../components/modal/PdfPreview'
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
import XEUtils from 'xe-utils'
export default {
  components: {
    HomeHeaderFixed,
    HomeFooter,
    PdfPreview,
  },
  data() {
    return {
      // 是否查询
      ifSearch: false,
      // 查询条件
      form: {
        productname: '',
        reportnum: '',
        reportdate: '',
      },
      // 查询结果类型
      resultType: '',
      // 查询结果表单
      resultForm: {
        id: 0,
        certnum: '',
        factoryname: '',
        productname: '',
        kv: '',
        issuedate: '',
        validtime: '',
        place: '',
        certfile: '',
        filePath: '',
        detailList: [],
      },
      // 查询校验
      rules: {
        productname: [],
        reportnum: [],
        reportdate: [],
      },
      // 下拉框选项
      comBoxItems: {
        productname: [],
      },
      // 报告文件地址
      PDFSrc: '',
      // 记录上次报告时间
      oldCerttDate: '',
      // 报告发生变化
      reportOrCertIsChange: false,
    }
  },
  mounted() {
    this.BindComBox()
  },
  activated() {
    this.initData()
  },
  methods: {
    // 日期格式化
    dateFormat(fmt, str) {
      if (!str) {
        return ''
      }
      var date = new Date(str)
      if (!date) {
        return ''
      }
      if (XEUtils.toDateString(date, 'yyyy-MM-dd') === '1900-01-01') {
        return ''
      } else {
        return XEUtils.toDateString(str, fmt)
      }
    },
    // 模态窗获取base64完毕回调事件
    handleGetBase64() {
      this.reportOrCertIsChange = false
    },
    // 变化时
    certDateChange(e) {
      if (!e) {
        this.oldCerttDate = ''
      }
    },
    // 输入事件
    inputcertDate(e) {
      const startIndex = String(e.target.value).indexOf('/')
      const endIndex = String(e.target.value).lastIndexOf('/')
      if (
        !this.oldCerttDate &&
        [8, 9, 10].includes(String(e.target.value).length) &&
        String(e.target.value).match(new RegExp(/\//g))?.length === 2 &&
        startIndex !== endIndex &&
        endIndex !== String(e.target.value).length - 1 &&
        Number(String(e.target.value)[(endIndex, String(e.target.value).length - 1)]) !== 0 &&
        XEUtils.isValidDate(new Date(e.target.value))
      ) {
        this.$refs.certDatePicker.userInput = XEUtils.toDateString(new Date(e.target.value), 'yyyy-MM-dd')
        this.oldCerttDate = ''
      }
      this.oldCerttDate = e.target.value
    },
    // 展示pdf文件
    handleShowPDF() {
      if (this.resultForm.filePath) {
        this.$refs.PdfPreview.formVisible = true
      }
    },
    // 初始化
    initData() {
      this.ifSearch = false
      this.resultForm = {
        id: 0,
        certnum: '', // 证书编号
        factoryname: '', // 企 业 名 称
        productname: '', // 产 品 名 称
        kv: '', // 电 压 等 级
        issuedate: '', // 发 证 日 期
        validtime: '', // 有 效 期
        place: '', // 省份
        certfile: '',
        filePath: '',
        detailList: [],
      }
    },
    // 绑定下拉框
    async BindComBox() {
      this.comBoxItems.productname = []
      const { data: selectRes } = await this.$http.get('/Company/GjgCert/select-item')
      if (selectRes.isSuccess) {
        for (let item of selectRes.data) {
          switch (item.code) {
            case 'productname':
              this.comBoxItems.productname.push({
                label: item.name,
                value: item.value,
              })
              break
          }
        }
      }
    },
    // 查找
    async handleSearch() {
      // 别问为什么是report，因为生爷不想改viewmodel
      if (!this.form.productname || !this.form.reportnum || !this.form.reportdate) {
        this.$message.error('请输入完整的查询条件!')
        return false
      }
      let flag = false
      if (this.form.productname === '环形混凝土电杆') {
        flag = await this.sureSearch('/Company/HndgCert/search')
      } else {
        flag = await this.sureSearch('/Company/GjgCert/search')
      }
      if (flag) {
        this.ifSearch = true
        this.resultType = this.form.productname
        this.reportOrCertIsChange = true
        // if (this.resultForm.filePath) {
        //   this.PDFSrc = ''
        //   const { data: resFile } = await this.$http.get('/Base/BAttachment/secret', {
        //     params: {
        //       urlstr: uploadURL + '/filedownload/' + this.resultForm.filePath
        //     }
        //   })
        //   if (typeof resFile !== 'string' && resFile.errorMessage) {
        //     this.$message.error('获取证书文件异常, 请联系管理员。')
        //   } else if (typeof resFile === 'string') {
        //     this.PDFSrc = resFile
        //   }
        // } else {
        //   this.PDFSrc = ''
        // }
      } else {
        this.ifSearch = false
      }
    },
    // 查询
    async sureSearch(API) {
      const { data: res } = await this.$http.post(API, this.form)
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.resultForm = res.data
      return true
    },
  },
  computed: {
    tableHeight() {
      return this.$store.state.clientHeight - 67 - 79.5 - 130 - 30 - 30 - 189 - 40 + 'px'
    },
    // 是否为手机端
    isMobile() {
      return this.$store.state.isMobile
    },
  },
}
</script>
<style lang="less" scoped>
@titleHeight: 5rem;
@footerHeight: 66.61px;
@pageBackground: #f5f5f5;
@topMargin: 30px;
@certSearchContainerHeight: 100px;
.HomeCert {
  height: 100%;
  background: @pageBackground;
}
.certContainer {
  padding-top: @titleHeight;
  height: calc(100% - @footerHeight);
}
.certSearchContainer {
  width: 75%;
  height: @certSearchContainerHeight;
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  display: flex;
  #searchForm {
    padding-left: 20px;
    width: calc(100% - 200px);
    height: 100%;
    border-radius: 10px 0 0 10px;
    .el-form {
      height: 100%;
      .el-row {
        height: 100%;
        .el-col {
          height: 100%;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          overflow: auto;
          padding: 0 10px;
          .el-select {
            width: calc(100% - 15px);
          }
          .el-input {
            width: calc(100% - 15px);
            transition: all 0;
          }
          .el-form-item {
            margin: 0;
            height: 100%;
          }
          /deep/ .el-form-item__label {
            padding: 0;
            margin-bottom: 5px;
          }
          /deep/ .el-input__inner {
            text-indent: 15px;
            padding: 0;
            border-radius: 5px;
            border: 1px solid #dcdfe6;
          }
          /deep/ .el-date-editor {
            .el-input__inner {
              text-indent: 35px;
            }
          }
          /deep/ .el-input__inner:focus {
            box-shadow: 0 0 0;
            border: 1px solid #4285f4;
          }
        }
      }
    }
  }
  #searchButton {
    width: 200px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .el-button {
      width: auto;
      padding: 0 35px;
      height: 50px;
      font-size: 16px;
    }
  }
}
.certResultContainer {
  width: 75%;
  height: calc(100% - @certSearchContainerHeight - @footerHeight - @topMargin);
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  box-sizing: border-box;
  padding: 15px 10px;
  overflow: auto;
  #nullData {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
.certFormContainer {
  .el-row {
    display: flex;
    justify-content: center;
  }
  .el-table {
    width: 100%;
    height: 100%;
    /deep/ .has-gutter {
      .cell {
        font-weight: bold !important;
      }
    }
  }
  /deep/ .el-form-item__content {
    margin-left: 82px;
    margin-right: 10px;
  }

  /deep/ .el-divider--horizontal {
    margin: 14px 0 !important;
  }
  .el-date-editor {
    width: 100%;
    /deep/ .el-input__inner {
      padding-left: 30px;
      padding-right: 15px;
    }
  }
  .el-form-item {
    /*el-form样式*/
    /deep/ .el-form-item__label {
      width: 82px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      margin: 0 0 !important;
    }
  }
}
.phoneCertSearchContainer {
  width: 75%;
  height: @certSearchContainerHeight;
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  display: flex;
  #phoneSearchForm {
    padding: 5px 20px;
    width: calc(100% - 40px);
    height: 100%;
    border-radius: 10px 0 0 10px;
    overflow: auto;
    .el-date-editor {
      width: 100%;
      /deep/ .el-input__inner {
        padding-left: 30px;
        padding-right: 15px;
      }
    }
    /deep/ .is-left {
      left: 0 !important;
    }
    /deep/ .el-form-item__content {
      margin-left: 60px;
      margin-right: 10px;
    }
    .el-form-item {
      margin-bottom: 1px !important;
    }
    /deep/ .el-form-item__label {
      height: 28px;
      width: 60px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      line-height: 28px !important;
      margin: 0 0 !important;
    }
  }
  #phoneSearchButton {
    width: 40px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .el-button {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}
.phoneSearchFormContainer {
  width: 100%;
  font-size: 14px;
  .el-col {
    margin-bottom: 5px;
  }
  // display: flex;
  // justify-content: center;
}
</style>
