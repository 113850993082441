<template>
  <div class="vueContainer" id="HomeContainer">
    <HomeHeader v-if="routerIsHome" :isActive="isActive" @handleJumpRouter="handleJumpRouter" />
    <HomePage />
    <HomeAbout ref="HomeAbout" />
    <!-- <HomeJobs /> -->
    <HomeContact />
    <HomeFooter />
  </div>
</template>

<script>
import HomeHeader from "./HomeHeader.vue";
import HomePage from "./HomePage.vue";
import HomeFooter from "./HomeFooter.vue";
import HomeAbout from "./HomeAbout.vue";
import HomeContact from "./HomeContact.vue";
// import HomeJobs from "./HomeJobs.vue";

import aboutUsHTML from '../../assets/js/AboutUs'
export default {
  name: "Home",
  components: {
    HomeHeader, HomePage, HomeFooter, HomeAbout, HomeContact,
    // HomeJobs
  },
  async created() {
    const that = this
    // 先绑定动态的界面，否则会导致滚动条出问题锚点不准
    await that.BindAboutUs()
    // 逻辑放在激活当中，此处注释
    // if (this.$router.currentRoute?.params?.sectionPath) {
    //   this.$nextTick(() => {
    //     this.handleJump('#' + this.$router.currentRoute.params.sectionPath)
    //   })
    // }
    // 进入时默认锚点为顶部
    window.onscroll = function () {
      if (that.$router.currentRoute.name === 'Home') {
        that.switchScroll(window.scrollY)
      }
    }
    this.$nextTick(() => {
      that.pageTop.about = document.getElementById('aboutContainerID').offsetTop - 74
      // that.pageTop.jobs = document.getElementById('jobsContainerID').offsetTop
      that.pageTop.contact = document.getElementById('contactContainerID').offsetTop - 74
      // 高度设置完后再进行设置选中
      that.switchScroll(window.scrollY)
    })
  },
  activated() {
    // 此参数用于重新渲染头，解决头部样式不响应
    this.routerIsHome = true
    // 查询路由是否有传回锚点，有的话则跳转
    if (this.$router.currentRoute?.params?.sectionPath) {
      this.$nextTick(() => {
        if ((this.$router.currentRoute.params.sectionPath).includes('pageTop')) {
          this.isActive = 'Home'
          this.handleJump('#')
        } else {
          this.handleJump('#' + this.$router.currentRoute.params.sectionPath)
        }
      })
    }
  },
  data() {
    return {
      // 当前激活tab
      isActive: '',
      // 当前激活tab的页面顶部距离
      pageTop: {
        about: 1,
        // jobs: 2,
        contact: 2
      },
      // 是否在home
      routerIsHome: true
    }
  },
  methods: {
    // 绑定中心简介html片段
    async BindAboutUs() {
      const that = this
      const { data: res } = await this.$http.get('/Company/AboutUs')
      if (!res.isSuccess || res.data === '') {
        this.$refs.HomeAbout.aboutContent = aboutUsHTML + ' '
      } else {
        this.$refs.HomeAbout.aboutContent = res.data + ' '
      }
      // 处理图片有放大功能
      this.$nextTick(() => {
        for (let item of document.getElementsByClassName('fr-dii')) {
          // item.style.cursor = 'pointer'
          item.style.cursor = 'zoom-in'
          item.onclick = function () {
            if (item.id) {
              // item.style.cursor = 'zoom-in'
              // item.style.height = item.id + 'px'
              // item.id = ''
              // 定位置空，还原至初始位置
              // item.style.position = ''
              // item.style.top = ''
              // item.style.left = ''
              // item.style.zIndex = 1
            } else {
              // item.style.cursor = 'zoom-out'
              // item.id = item.height
              // item.style.height = ''
              // 定位至全屏模式
              // item.style.position = 'fixed'
              // item.style.top = 0
              // item.style.left = 0
              // item.style.zIndex = 999999
              // 全屏
              //  ==> 为什么不做在本页面呢?
              //  ==> 因为如果放在当前页面会存在滚动条看着很诧异
              that.$store.commit('set_FULLSCREENIMG', item.cloneNode(true))
              that.$store.commit('set_FULLSCREENFLAG', true)
            }
          }
        }
      })
    },
    // 跳转
    handleJump(href) {
      const eleLink = document.createElement("a");
      eleLink.href = href;
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    // 锚点
    switchScroll(number) {
      if (number < this.pageTop.about) {
        // 此处滚动条为0监听不到，暂时保留，但实际在上面已经处理
        this.isActive = '#'
      } else if (this.pageTop.about <= number && number < this.pageTop.contact) {
        this.isActive = '#about'
      }
      // else if (this.pageTop.jobs <= number && number < this.pageTop.contact) {
      //   this.isActive = 'Jobs'
      // }
      else if (this.pageTop.contact <= number) {
        this.isActive = '#contact'
      } else {
        this.isActive = ''
      }
    },
    // 跳转了路由
    handleJumpRouter() {
      this.routerIsHome = false
    }
  }
};
</script>

<style scoped>
.vueContainer {
  height: 100%;
  width: 100%;
}
</style>
