<template>
  <div class="HomeNews">
    <HomeHeaderFixed isActive="News" />
    <div class="newsContainer">
      <div class="newsContent" v-show="!isDetail">
        <div class="newsLeft">
          <div id="newsLeftWindow">
            <div id="newsLeftTitle" style="font-weight: 500">新闻动态</div>
            <div id="newsLeftContent">
              <ul>
                <li
                  :class="item.name === '全部分类' ? 'nodeIsAll' : ''"
                  v-for="(item, index) of treeNodeList"
                  :key="index"
                  :style="currentNode === item.name ? 'border-left: 5px #4e62e9 solid;padding-left: 5px;' : ''"
                  @click="handleClick(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="newsRight">
          <div id="newsRightWindow">
            <div id="newsRightTitle" style="font-weight: 500">
              <div id="titleBlock"></div>
              {{ currentNode }}
            </div>
            <div id="newsRightContent">
              <div id="hasData" v-if="currentNodeList.length > 0">
                <ul>
                  <li v-for="(item, index) of currentNodeList" :key="index" @click="handleEnterDetail(item)">
                    <span id="title">{{ titleString(item.title) }}</span>
                    <span id="date">{{ item.writeDate }}</span>
                  </li>
                </ul>
              </div>
              <div id="notData" v-else>
                <img src="../../assets/nullData.png" style="width: 120px; height: 120px" alt="暂无数据" />
              </div>
            </div>
            <!-- <div id="newsRightFooter">
              <div id="pageMsg">第{{ pageParms.pageIndex }}页 / 共{{ pageParms.pageCount }}页</div>
              <el-button size="mini" icon="el-icon-d-arrow-left">首页</el-button>
              <el-button size="mini" icon="el-icon-back">上一页</el-button>
              <el-button size="mini" icon="el-icon-right">下一页</el-button>
              <el-button size="mini" icon="el-icon-d-arrow-right">尾页</el-button>
              <el-input-number
                v-model="pageParms.pageIndex"
                size="mini"
                :min="1"
                :max="pageParms.pageCount"
                :controls="false"
              >
              </el-input-number>
              <el-button size="mini">跳转</el-button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="newsContentDetail" v-if="isDetail">
        <div class="breadcrumbContainer">
          <el-breadcrumb>
            <el-breadcrumb-item><a @click="handleBack">新闻动态</a></el-breadcrumb-item>
            <el-breadcrumb-item>详细</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="detailContainer" id="detailContainerId">
          <div id="detailHeader">
            <el-row class="titleRow">
              <el-col :span="24">
                <h3 :style="detailData.titleStyle ? detailData.titleStyle : 'color: red;font-weight:bold'">
                  {{ detailData.title }}
                </h3>
              </el-col>
            </el-row>
            <el-row class="contentRow">
              <el-col :span="24">
                <span>{{ detailData.writeDate }}</span>
              </el-col>
            </el-row>
          </div>
          <div id="VDomDetailContent">
            <div v-html="vDomContent"></div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
  </div>
</template>
<script>
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
export default {
  components: {
    HomeHeaderFixed,
    HomeFooter
  },
  data() {
    return {
      pageParms: {
        pageIndex: 1, // 当前页
        pageCount: 1
      },
      treeNodeList: [
        {
          name: '全部分类',
          children: []
        },
        {
          name: '行业咨询',
          children: []
        },
        {
          name: '工作动态',
          children: []
        },
        {
          name: '技术专栏',
          children: []
        }
      ],
      currentNode: '',
      currentNodeList: [],
      // 是否为明细
      isDetail: false,
      // 详情页数据
      detailData: {
        title: '',
        content: '',
        writeDate: '',
        titleStyle: ''
      },
      vDomContent: ``
    }
  },
  created() {
    this.BindData()
  },
  mounted() {
  },
  methods: {
    // 获取索引
    getItemIndex(type) {
      return this.treeNodeList.findIndex(item => item.name === type)
    },
    // 绑定数据
    async BindData() {
      const { data: res } = await this.$http.get('/Company/News/list')
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      // 赋值到各个数组中
      for (let item of res.data) {
        this.treeNodeList[this.getItemIndex(item.type)].children.push(item)
      }
      // const all = {
      //   name: '全部分类',
      //   children: []
      // }
      for (let item of this.treeNodeList) {
        for (let detail of item.children) {
          this.treeNodeList[this.getItemIndex('全部分类')].children.push(detail)
        }
      }
      // this.treeNodeList.unshift(all)
      this.handleClick(this.treeNodeList[0])
    },
    // 点击节点
    handleClick(item) {
      this.currentNode = item.name
      this.currentNodeList = item.children
    },
    handleBack() {
      this.detailData = {
        title: '',
        content: '',
        writeDate: '',
        titleStyle: ''
      }
      this.vDomContent = ''
      this.$nextTick(() => {
        this.isDetail = false
      })
    },
    // 处理title过长的字符串
    titleString(str) {
      return str.length > 24 ? str.substr(0, 25) + '...' : str
    },
    // 进入明细
    async handleEnterDetail(item) {
      const { data: res } = await this.$http.get('/Company/News', {
        params: {
          id: item.id
        }
      })
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.detailData = res.data
      // this.detailData = {
      //   title: item.title,
      //   writeDate: item.writeDate,
      //   titleStyle: item.titleStyle
      // }
      // 表格增加边框
      const re = new RegExp(/\<table /g)
      // let content = item.content + ' '
      let content = res.data.contents + ' '
      content = content.replace(re, '<table border=1')
      this.vDomContent = content
      this.$nextTick(() => {
        this.isDetail = true
      })
    }
  }
}
</script>
<style lang="less" scoped>
.HomeNews {
  height: 100%;
  background: #f5f5f5;
}
.newsContainer {
  padding-top: 5rem;
  height: calc(100% - 66.61px);
}
.newsContent {
  width: 75%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
}
.newsLeft {
  width: 260px;
  margin-right: 25px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.newsRight {
  height: 100%;
  width: calc(100% - 300px);
}
#newsLeftWindow {
  height: 280px;
  width: 80%;
  /* background: blueviolet; */
  border-radius: 5px;
  border: 1px solid #ada8aa;
}
#newsLeftWindow #newsLeftTitle {
  height: 50px;
  background: #e3dede;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #ada8aa;
}
.nodeIsAll {
  font-weight: bold;
}
#newsLeftWindow #newsLeftContent {
  border-radius: 0 0 5px 5px;
  padding: 10px;
  padding-left: 30px;
}
#newsLeftContent ul {
  padding-left: 0px;
}
#newsLeftContent li {
  margin-bottom: 8px;
  font-size: 14px;
  list-style: none;
  cursor: pointer;
}
#newsLeftContent li:hover {
  color: #999;
  transition: all 0.3s;
}
#newsRightWindow {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #ada8aa;
}
#newsRightWindow #newsRightTitle {
  height: 50px;
  background: #e3dede;
  border-radius: 5px 5px 0 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #ada8aa;
}
#titleBlock {
  width: 5px;
  height: 20px;
  background: #3949ab;
  margin-right: 10px;
}
#newsRightWindow #newsRightContent {
  height: calc(100% - 60px);
  // height: calc(100% - 50px - 60px);
  width: 100%;
  padding: 10px 0;
}
#newsRightWindow #newsRightFooter {
  height: 60px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
}
#newsRightFooter .el-input-number {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 10px;
  border: 1px solid #dcdfe6;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner {
  width: 48px;
  height: 38px;
  padding: 0 5px;
  border: 0;
  border-radius: 5px;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner:hover {
  border: 0;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner:focus {
  border: 0;
  box-shadow: 0 0 0 0;
}
#newsRightFooter #pageMsg {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;
  border: 1px solid #dcdfe6;
  background: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #606266;
  font-weight: 500;
}
#newsRightContent #notData {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#newsRightContent #hasData {
  height: 100%;
  overflow: auto;
  padding: 5px 0;
}
/*滚动条整体样式*/
#newsRightContent #hasData::-webkit-scrollbar {
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
/*滚动条里面小方块*/
#newsRightContent #hasData::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #cecccc;
}
/*滚动条里面轨道*/
#newsRightContent #hasData::-webkit-scrollbar-track {
  box-shadow: 0 0 0 0;
  border-radius: 10px;
  background: #f5f5f5;
}
#newsRightContent #hasData ul {
  /* height: 100%; */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#newsRightContent #hasData ul li {
  height: 44px;
  width: 100%;
  padding: 0 30px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdfe6;
}
#newsRightContent #hasData ul li:hover {
  cursor: pointer;
  padding: 0 30px 0 40px;
  background: #e6e4e4;
  transition: all 0.2s;
}
#newsRightContent #hasData ul li:hover span {
  color: #3949ab;
  transition: all 0.2s;
}
#hasData li #title {
  color: #606266;
  font-size: 16px;
  font-weight: 400;
}
#hasData li #date {
  color: #9898a0;
  font-size: 14px;
}
@breadcrumbHeight: 30px;
.newsContentDetail {
  width: 75%;
  height: 100%;
  margin: 0 auto;
  padding: 10px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  .breadcrumbContainer {
    width: 100%;
    height: @breadcrumbHeight;
    padding: 0;
    display: flex;
    align-items: center;
  }
}
.detailContainer {
  height: calc(100% - 40px);
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  @headerHeight: 88px;
  overflow: auto;
  #detailHeader {
    height: @headerHeight;
    width: 100%;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #d3d0d0;
    overflow: auto;
    padding: 20px 20px 0 20px;
    position: relative;
    .titleRow .el-col {
      display: flex;
      justify-content: center;
      padding: 0;
      h3 {
        margin: 0;
      }
    }
    .contentRow {
      position: absolute;
      right: 0;
      bottom: 10px;
      .el-col {
        display: flex;
        justify-content: flex-end;
        padding: 0 10px;
        height: 18px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  #VDomDetailContent {
    height: calc(100% - @headerHeight);
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 20px 50px 5px 50px;
    // overflow: auto;
  }
}
/*滚动条整体样式*/
#detailHeader::-webkit-scrollbar,
.detailContainer::-webkit-scrollbar {
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
/*滚动条里面小方块*/
#detailHeader::-webkit-scrollbar-thumb,
.detailContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #cecccc;
}
/*滚动条里面轨道*/
#detailHeader::-webkit-scrollbar-track,
.detailContainer::-webkit-scrollbar-track {
  box-shadow: 0 0 0 0;
  border-radius: 10px;
  background: #f5f5f5;
}
</style>