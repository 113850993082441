<template>
  <vxe-modal
    ref="modal"
    :zIndex="99999"
    size="mini"
    v-model="formVisible"
    esc-closable
    title="预览"
    width="100%"
    height="100%"
    @show="showModal"
    @hide="hideModal"
    remember
  >
    <div id="closeAndRotateContainer">
      <el-button type="danger" icon="el-icon-close" size="mini" @click="handleClose()">关闭预览窗口</el-button>
    </div>
    <div
      id="pdf-container"
      :style="{
        justifyContent: `${clientWidth > 768 ? 'center' : 'flex-start'}`,
      }"
    >
      <div class="pdfArea" :key="filePath">
        <img :src="PDFSrc" alt="报告" />
      </div>
    </div>
  </vxe-modal>
</template>
<script>
export default {
  props: {
    // 报告是否有变化 => 主要用于报告未变时缓存图片
    reportOrCertIsChange: {
      type: Boolean,
      default: false
    },
    // 文件路径 => 用于接口调用
    filePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formVisible: false,
      // ----- vuepdf -----
      PDFSrc: ``
    }
  },
  methods: {
    // 获取base64图片
    async getBase64Img() {
      this.PDFSrc = ''
      const { data: resFileBase64 } = await this.$http.get('/Base/BAttachment/download', {
        params: {
          filePath: this.filePath
        }
      })
      if (!resFileBase64.isSuccess) {
        this.$message.error(resFileBase64.errorMessage)
        this.formVisible = false
        return false
      }
      this.PDFSrc = 'data:image/jpg;base64,' + resFileBase64.data
    },
    // 关闭窗口
    handleClose() {
      this.formVisible = false
    },
    // 开启模态窗
    async showModal() {
      if (this.reportOrCertIsChange) {
        await this.getBase64Img()
        this.$emit('handleGetBase64')
      }
      // 禁用右键、F12
      this.prohibit()
    },
    // 关闭模态窗
    hideModal() {
      // 恢复被禁用的按钮
      this.openProhibit()
    },
    // 禁用鼠标右击、F12
    prohibit() {
      document.oncontextmenu = function () {
        return false
      }
      document.onkeydown = function (e) {
        if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e.keyCode === 117)) {
          return false
        }
        if (e.keyCode === 18 || e.keyCode === 123) {
          return false
        }
      }
    },
    // 恢复被禁用的按钮
    openProhibit() {
      document.oncontextmenu = function () {
        return true
      }
      document.onkeydown = function (e) {
        if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e.keyCode === 117)) {
          return true
        }
        if (e.keyCode === 18 || e.keyCode === 123) {
          return true
        }
      }
    },
  },

  computed: {
    // 浏览器宽度
    clientWidth() {
      return Number(this.$store.state.clientWidth)
    }
  }
}
</script>
<style lang='less' scoped>
#closeAndRotateContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  margin: 0 0 10px 0;
  height: 30px;
}
// 底部按钮css
#modalButtonContainer {
  height: 60px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
}
#modalButtonContainer .el-input-number {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 10px;
  border: 1px solid #dcdfe6;
}
/deep/ #modalButtonContainer .el-input-number .el-input__inner {
  width: 48px;
  height: 38px;
  padding: 0 5px;
  border: 0;
  border-radius: 5px;
}
/deep/ #modalButtonContainer .el-input-number .el-input__inner:hover {
  border: 0;
}
/deep/ #modalButtonContainer .el-input-number .el-input__inner:focus {
  border: 0;
  box-shadow: 0 0 0 0;
}
#modalButtonContainer #pageMsg {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;
  border: 1px solid #dcdfe6;
  background: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #606266;
  font-weight: 500;
}

#pdf-container {
  overflow: auto;
  height: calc(100vh - 15px - 40px - 40px);
  font-family: PingFang SC;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.pdfArea {
  min-width: 768px;
  // height: 300px;
}

/* ------------------- 输入页码 ------------------- */
/*在谷歌下移除input[number]的上下箭头*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
/*在firefox下移除input[number]的上下箭头*/
input[type='number'] {
  -moz-appearance: textfield;
}

/* ------------------- 进度条 ------------------- */
.elprogress {
  position: fixed;
  // top: calc(50% - 60px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
}
.elprogress > span {
  color: #199edb;
  font-size: 14px;
}
/* ------------------- 水印 ------------------- */
.flag {
  transform: rotate(-60deg);
  /* height: 753px; */
  width: 1700px;
  letter-spacing: 30px;
  /* background-color: brown; */
  z-index: 1;
  position: absolute;
  color: #00aeff;
  font-size: 90px;
  opacity: 0.2;
  top: 90%;
  /* left: 5%; */
}
</style>