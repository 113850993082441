import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import News from '../views/Home/HomeNews.vue'
import Download from '../views/Home/HomeDownload.vue'
import Cert from '../views/Home/HomeCert.vue'
import Report from '../views/Home/HomeReport.vue'
import Entrust from '../views/Home/HomeEntrust.vue'
import Jobs from '../views/Home/HomeJobsPage.vue'

Vue.use(VueRouter)
const routes = [{
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Download',
    name: 'Download',
    component: Download
  },
  {
    path: '/Cert',
    name: 'Cert',
    component: Cert
  },
  {
    path: '/Report',
    name: 'Report',
    component: Report
  },
  {
    path: '/Entrust',
    name: 'Entrust',
    component: Entrust
  },
  {
    path: '/Jobs',
    name: 'Jobs',
    component: Jobs
  },
]


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  // 禁用前进后退
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }
})
// router.beforeEach((to, before, next) => {
//   next()
// })

export default router