import Vue from "vue";
import {
  Tabs,
  TabPane,
  Row,
  Col,
  Table,
  TableColumn,
  Dialog,
  Form,
  FormItem,
  Input,
  Autocomplete,
  Radio,
  RadioGroup,
  Button,
  Message,
  MessageBox,
  Select,
  Option,
  Image,
  Link,
  Divider,
  Popover,
  InputNumber,
  DatePicker,
  Breadcrumb,
  BreadcrumbItem,
  Progress,
  Pagination
} from "element-ui";

Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Link);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Select);
Vue.use(Option);
Vue.use(Divider);
Vue.use(Popover);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Progress);
Vue.use(Pagination)

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
