<template>
  <div class="homeFooter">
    <footer class="page-footer indigo darken-2 center-on-small-only pt-0 mt-0">
      <div class="container">
        <div class="row">
          <div class="PartnersItems">
            <div style="width: 100%; text-align: center">合作伙伴</div>
            <!-- <div class="flex-center"> -->
            <!-- <div class="PartnersItem">
                <el-popover placement="top" trigger="hover">
                  <div style="width: 100%; text-align: center">国家质检总局</div>
                  <img
                    class="jumpImg"
                    slot="reference"
                    @click="handleJump('http://www.aqsiq.gov.cn')"
                    src="../../assets/国家质检总局.png"
                  />
                </el-popover>
              </div> -->
            <div class="PartnersItem">
              <el-popover placement="top" trigger="hover">
                <div style="width: 100%; text-align: center">国家电网公司</div>
                <img
                  class="jumpImg"
                  slot="reference"
                  @click="handleJump('http://www.sgcc.com.cn/')"
                  src="../../assets/国网.png"
                />
              </el-popover>
            </div>
            <div class="PartnersItem">
              <el-popover placement="top" trigger="hover">
                <div style="width: 100%; text-align: center">中国南方电网</div>
                <img
                  class="jumpImg"
                  slot="reference"
                  @click="handleJump('http://www.csg.cn/')"
                  src="../../assets/南网.png"
                />
              </el-popover>
            </div>
            <div class="PartnersItem">
              <el-popover placement="top" trigger="hover">
                <div style="width: 100%; text-align: center">铁塔联盟网</div>
                <img
                  class="jumpImg"
                  slot="reference"
                  @click="handleJump('https://www.ttlm.net/')"
                  src="../../assets/ttlmLogo.png"
                />
              </el-popover>
            </div>
            <!-- <a class="px-3"></a><a class="px-3"><i class="fa fa-twitter fa-lg white-text"></i></a
              ><a class="px-3"><i class="fa fa-google-plus fa-lg white-text"></i></a
              ><a class="px-3"><i class="fa fa-linkedin fa-lg white-text"></i></a> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="footerHtmlMessage">
        <div class="footText">
          <el-row>
            <el-col :span="24">
              <img style="width: 25px; height: 25px" src="../../assets/beian.png" alt="备案图标" />&nbsp;
              <!-- <span>备案号:</span> -->
              <a id="footLink" target="_blank" href="http://beian.miit.gov.cn/"> 闽ICP备17024441号-1</a> &emsp;
              <!-- <span>邮编：350109 </span> &emsp; -->
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <span>联系电话：0591-83767516</span> &emsp;<span>传真：0591-83767516</span>&emsp;
              <span>Email：fzgddl@126.com</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <span>地址：福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201</span>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="24">
              <span>Copyright © 2022 电力工业电力设备及线路器材质量检验测试中心</span> &emsp;
            </el-col>
          </el-row>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    handleJump(href) {
      window.open(href, '_blank')
      // const eleLink = document.createElement("a");
      // eleLink.download = 'logo'
      // eleLink.style.display = href
      // eleLink.href = href;
      // 触发点击
      // document.body.appendChild(eleLink);
      // eleLink.click();
      // 然后移除
      // document.body.removeChild(eleLnk);
    },
  },
}
</script>
<style scoped>
.homeFooter {
  height: auto;
  /* padding: 30px 0; */
  background: #252525;
}
#beianhao {
  color: #939393;
}
#beianhao:hover {
  color: #3949ab;
}
.footText {
  width: 100%;
}
.footText span {
  color: #fff;
  font-weight: bold;
}
#footLink {
  font-weight: bold;
}
#footLink:hover {
  color: #999;
}
.footerHtmlMessage {
  /* overflow: hidden; */
  /* height: 50px; */
  /* line-height: 50px; */
  width: 100%;
  padding: 10px 0;
  height: auto;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 0.9rem;
  background: #26327f;
}
/* .mb-5 {
  margin-bottom: 2rem !important;
} */
.PartnersItem {
  /* width: 100px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
}
.PartnersItems {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 5px 0;
  font-weight: bold;
}
.jumpImg {
  width: 50px;
  height: 50px;
  /* margin: 0 20px; */
  /* margin-bottom: 5px; */
}
.jumpImg:hover {
  cursor: pointer;
}
</style>
