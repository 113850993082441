<template>
  <div>
    <div id="loading3">
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
      <div class="demo3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loading'
}
</script>

<style lang="less" scoped>
#loading3 {
  position: relative;
  width: 100px;
  height: 100px;
  // background: rgba(255, 255, 255, 0.1);
}
.demo3 {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #68b2ce;
  position: absolute;
  animation: demo3 linear 0.8s infinite;
  -webkit-animation: demo3 linear 0.8s infinite;
}
.demo3:nth-child(1) {
  left: 48px;
  top: 4px;
  animation-delay: 0s;
}
.demo3:nth-child(2) {
  left: 80px;
  top: 16px;
  animation-delay: 0.1s;
}
.demo3:nth-child(3) {
  left: 94px;
  top: 48px;
  animation-delay: 0.1s;
}
.demo3:nth-child(4) {
  left: 80px;
  top: 80px;
  animation-delay: 0.2s;
}
.demo3:nth-child(5) {
  left: 48px;
  top: 94px;
  animation-delay: 0.4s;
}
.demo3:nth-child(6) {
  left: 16px;
  top: 80px;
  animation-delay: 0.5s;
}
.demo3:nth-child(7) {
  left: 4px;
  top: 48px;
  animation-delay: 0.6s;
}
.demo3:nth-child(8) {
  left: 16px;
  top: 16px;
  animation-delay: 0.7s;
}

@keyframes demo3 {
  0%,
  40%,
  100% {
    transform: scale(1);
  }
  20% {
    transform: scale(2);
  }
}
@-webkit-keyframes demo3 {
  0%,
  40%,
  100% {
    transform: scale(1);
  }
  20% {
    transform: scale(2);
  }
}
</style>
