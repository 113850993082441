<template>
  <div class="HomeReport">
    <HomeHeaderFixed isActive="Report" />
    <div class="reportContainer">
      <div class="reportSearchContainer" v-if="!isMobile">
        <div id="searchForm">
          <el-form @keyup.enter.native="handleSearch()" label-position="top" :model="form" :rules="rules">
            <el-row>
              <el-col :span="8">
                <el-form-item label="报告类型">
                  <el-select v-model="form.productname" clearable placeholder="请选择">
                    <el-option
                      v-for="(item, index) of comBoxItems.type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="报告编号">
                  <el-input v-model="form.reportnum" clearable placeholder="输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="报告日期">
                  <el-date-picker
                    ref="reportDatePicker"
                    @input.native="inputReportDate"
                    @change="reportDateChange"
                    v-model="form.reportdate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="输入"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div id="searchButton">
          <el-button type="primary" @click="handleSearch()" icon="el-icon-search">查找</el-button>
        </div>
      </div>
      <div class="phoneReportSearchContainer" v-else>
        <div id="phoneSearchForm">
          <el-form @keyup.enter.native="handleSearch()" size="mini" :model="form" :rules="rules">
            <el-row>
              <el-col :span="24">
                <el-form-item label="报告类型">
                  <el-select v-model="form.productname" clearable placeholder="请选择">
                    <el-option
                      v-for="(item, index) of comBoxItems.type"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="报告编号">
                  <el-input v-model="form.reportnum" placeholder="输入" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="报告日期">
                  <el-date-picker
                    ref="reportDatePicker"
                    @input.native="inputReportDate"
                    @change="reportDateChange"
                    v-model="form.reportdate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="输入"
                    clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div id="phoneSearchButton">
          <el-button type="primary" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <div class="reportResultContainer">
        <div id="nullData" v-if="!ifSearch">
          <img src="../../assets/nullData.png" alt="暂无数据" />
        </div>
        <div class="reportFormContainer" v-else>
          <el-form :model="resultForm" v-if="!isMobile">
            <el-row :gutter="10">
              <el-col :span="resultForm.filePath ? 16 : 20">
                <el-form-item label="报告编号">
                  <!-- <div slot="label">
                    <el-link type="primary" @click="handleShowPDF" v-if="PDFSrc">报告编号</el-link>
                    <span v-else>报告编号</span>
                  </div> -->
                  <el-input v-model="resultForm.reportnum" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="resultForm.filePath" :span="4">
                <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 40px">
                  <el-button type="primary" @click="handleShowPDF">查看报告文件</el-button>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="20">
                <el-form-item label="企业名称">
                  <el-input v-model="resultForm.factoryname" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="20">
                <el-form-item label="产品名称">
                  <el-input v-model="resultForm.productname" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="10">
                <el-form-item label="报告日期">
                  <el-date-picker
                    v-model="resultForm.reportdate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="自动获取"
                    readonly
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="检测类别">
                  <el-input v-model="resultForm.testclass" placeholder="自动获取" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="20">
                <el-table :data="resultForm.detailList" border :height="tableHeight">
                  <el-table-column prop="typeSpec" label="型号规格" align="center" header-align="center">
                  </el-table-column>
                  <el-table-column prop="typeResult" label="检测结论" align="center" header-align="center">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-form>
          <div class="phoneSearchFormContainer" v-else>
            <el-row>
              <el-col :span="24"> 报告编号： {{ resultForm.reportnum }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 企业名称： {{ resultForm.factoryname }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 产品名称： {{ resultForm.productname }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 报告日期： {{ this.dateFormat('yyyy-MM-dd', resultForm.reportdate) }} </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"> 检测类别： {{ resultForm.testclass }} </el-col>
            </el-row>
            <el-row>
              <el-col v-if="resultForm.filePath" :span="24">
                <el-button type="primary" size="mini" style="width: 100%" @click="handleShowPDF"
                  >查看报告文件</el-button
                >
              </el-col>
            </el-row>
            <el-row justify="center">
              <el-col :span="24">
                <el-table :data="resultForm.detailList" border>
                  <el-table-column prop="typeSpec" label="型号规格" align="center" header-align="center">
                  </el-table-column>
                  <el-table-column prop="typeResult" label="检测结论" align="center" header-align="center">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
    <!-- <PdfPreview ref="PdfPreview" :fromPDFSrc="PDFSrc" /> -->
    <PdfPreview
      ref="PdfPreview"
      :filePath="resultForm.filePath"
      :reportOrCertIsChange="reportOrCertIsChange"
      @handleGetBase64="handleGetBase64()"
    />
    <!-- </div> -->
  </div>
</template>
<script>
// let { upload: uploadURL } = WebApiBaseUrl
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
import PdfPreview from '../../components/modal/PdfPreview'
import XEUtils from 'xe-utils'
export default {
  components: {
    HomeHeaderFixed,
    HomeFooter,
    PdfPreview,
  },
  data() {
    return {
      // 是否查询
      ifSearch: false,
      // 查询条件
      form: {
        productname: '',
        reportnum: '',
        reportdate: '',
      },
      // 查询结果
      resultForm: {
        id: 0,
        reportnum: '',
        factoryname: '',
        productname: '',
        reportdate: '',
        testclass: '',
        place: '',
        detailList: [],
      },
      // 查询校验
      rules: {
        productname: [],
        reportnum: [],
        reportdate: [],
      },
      // 下拉框选项
      comBoxItems: {
        type: [],
      },
      // 报告文件地址
      PDFSrc: '',
      // 记录上次报告时间
      oldReportDate: '',
      // 报告发生变化
      reportOrCertIsChange: false,
    }
  },
  mounted() {
    this.BindComBox()
  },
  activated() {
    this.initData()
    // const that = this
    // document.addEventListener('paste', function (e) {
    //   var clipboardData = e.clipboardData;
    //   if (!(clipboardData && clipboardData.items)) {//是否有粘贴内容
    //     return;
    //   }
    //   for (var i = 0, len = clipboardData.items.length; i < len; i++) {
    //     var item = clipboardData.items[i];
    //     if (item.kind === "string" && item.type == "text/plain") {
    //       item.getAsString(async function (str) {
    //         // console.log(str)
    //         // document.getElementsByClassName('el-input__inner')
    //         that.form.productname = str.split('	')[0]
    //         that.form.reportnum = str.split('	')[1]
    //         that.form.reportdate = str.split('	')[2]
    //         await that.handleSearch()
    //         that.handleShowPDF()
    //         // str 是获取到的字符串,创建文本框
    //         //处理粘贴的文字内容
    //       })
    //     }
    //   }
    // })
  },
  methods: {
    // 日期格式化
    dateFormat(fmt, str) {
      if (!str) {
        return ''
      }
      var date = new Date(str)
      if (!date) {
        return ''
      }
      if (XEUtils.toDateString(date, 'yyyy-MM-dd') === '1900-01-01') {
        return ''
      } else {
        return XEUtils.toDateString(str, fmt)
      }
    },
    // 模态窗获取base64完毕回调事件
    handleGetBase64() {
      this.reportOrCertIsChange = false
    },
    // 变化时
    reportDateChange(e) {
      if (!e) {
        this.oldReportDate = ''
      }
    },
    // 输入事件
    inputReportDate(e) {
      const startIndex = String(e.target.value).indexOf('/')
      const endIndex = String(e.target.value).lastIndexOf('/')
      if (
        !this.oldReportDate &&
        [8, 9, 10].includes(String(e.target.value).length) &&
        String(e.target.value).match(new RegExp(/\//g))?.length === 2 &&
        startIndex !== endIndex &&
        endIndex !== String(e.target.value).length - 1 &&
        Number(String(e.target.value)[(endIndex, String(e.target.value).length - 1)]) !== 0 &&
        XEUtils.isValidDate(new Date(e.target.value))
      ) {
        this.$refs.reportDatePicker.userInput = XEUtils.toDateString(new Date(e.target.value), 'yyyy-MM-dd')
        this.oldReportDate = ''
      }
      this.oldReportDate = e.target.value
    },
    // 展示pdf文件
    handleShowPDF() {
      if (this.resultForm.filePath) {
        this.$refs.PdfPreview.formVisible = true
      }
    },
    // 初始化
    initData() {
      this.ifSearch = false
      this.resultForm = {
        id: 0,
        reportnum: '',
        factoryname: '',
        productname: '',
        reportdate: '',
        testclass: '',
        place: '',
        detailList: [],
      }
    },
    // 绑定下拉框
    async BindComBox() {
      const { data: res } = await this.$http.get('/Company/Report/select-item')
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.comBoxItems.type = res.data
    },
    // 查找
    async handleSearch() {
      if (!this.form.productname || !this.form.reportnum || !this.form.reportdate) {
        this.$message.error('请输入完整的查询条件!')
        return false
      }
      const { data: res } = await this.$http.post('/Company/Report/search', {
        productname: this.form.productname,
        reportnum: this.form.reportnum,
        reportdate: this.form.reportdate,
      })
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.resultForm = res.data
      this.reportOrCertIsChange = true
      // if (res.data.filePath) {
      //   this.PDFSrc = ''
      // const { data: resFile } = await this.$http.get('/Base/BAttachment/secret', {
      //   params: {
      //     urlstr: uploadURL + '/filedownload/' + res.data.filePath
      //   }
      // })
      // if (typeof resFile !== 'string' && resFile.errorMessage) {
      //   this.$message.error('获取报告文件异常, 请联系管理员。')
      // } else if (typeof resFile === 'string') {
      //   this.PDFSrc = resFile
      // }
      // const { data: resFileBase64 } = await this.$http.get('/Base/BAttachment/download', {
      //   params: {
      //     filePath: res.data.filePath
      //   }
      // })
      // if (!resFileBase64.isSuccess) {
      //   this.$message.error(resFileBase64.errorMessage)
      //   return false
      // }
      // this.PDFSrc = 'data:image/jpg;base64,' + resFileBase64.data
      // } else {
      //   this.PDFSrc = ''
      // }
      this.ifSearch = true
    },
  },
  computed: {
    tableHeight() {
      return this.$store.state.clientHeight - 67 - 79.5 - 130 - 30 - 30 - 252 - 40 + 'px'
    },
    // 是否为手机端
    isMobile() {
      return this.$store.state.isMobile
    },
  },
}
</script>
<style lang="less" scoped>
@titleHeight: 5rem;
@footerHeight: 66.61px;
@pageBackground: #f5f5f5;
@topMargin: 30px;
@reportSearchContainerHeight: 100px;
.HomeReport {
  height: 100%;
  background: @pageBackground;
}
.reportContainer {
  padding-top: @titleHeight;
  height: calc(100% - @footerHeight);
}
.reportSearchContainer {
  width: 75%;
  height: @reportSearchContainerHeight;
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  display: flex;
  #searchForm {
    padding-left: 20px;
    width: calc(100% - 200px);
    height: 100%;
    border-radius: 10px 0 0 10px;
    .el-form {
      height: 100%;
      .el-row {
        height: 100%;
        .el-col {
          height: 100%;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          overflow: auto;
          padding: 0 10px;
          .el-select {
            width: calc(100% - 15px);
          }
          .el-input {
            width: calc(100% - 15px);
            transition: all 0;
          }
          .el-form-item {
            margin: 0;
            height: 100%;
          }
          /deep/ .el-form-item__label {
            padding: 0;
            margin-bottom: 5px;
          }
          /deep/ .el-input__inner {
            text-indent: 15px;
            padding: 0;
            border-radius: 5px;
            border: 1px solid #dcdfe6;
          }
          /deep/ .el-date-editor {
            .el-input__inner {
              text-indent: 35px;
            }
          }
          /deep/ .el-input__inner:focus {
            box-shadow: 0 0 0;
            border: 1px solid #4285f4;
          }
        }
      }
    }
  }
  #searchButton {
    width: 200px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .el-button {
      width: auto;
      padding: 0 35px;
      height: 50px;
      font-size: 16px;
    }
  }
}
.phoneReportSearchContainer {
  width: 75%;
  height: @reportSearchContainerHeight;
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  display: flex;
  #phoneSearchForm {
    padding: 5px 20px;
    width: calc(100% - 40px);
    height: 100%;
    border-radius: 10px 0 0 10px;
    overflow: auto;
    .el-date-editor {
      width: 100%;
      /deep/ .el-input__inner {
        padding-left: 30px;
        padding-right: 15px;
      }
    }
    /deep/ .is-left {
      left: 0 !important;
    }
    /deep/ .el-form-item__content {
      margin-left: 60px;
      margin-right: 10px;
    }
    .el-form-item {
      margin-bottom: 1px !important;
    }
    /deep/ .el-form-item__label {
      height: 28px;
      width: 60px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      line-height: 28px !important;
      margin: 0 0 !important;
    }
  }
  #phoneSearchButton {
    width: 40px;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .el-button {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}
.phoneSearchFormContainer {
  width: 100%;
  font-size: 14px;
  .el-col {
    margin-bottom: 5px;
  }
  // display: flex;
  // justify-content: center;
}

.reportResultContainer {
  width: 75%;
  height: calc(100% - @reportSearchContainerHeight - @footerHeight - @topMargin);
  margin: @topMargin auto 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #d3d2d2;
  box-sizing: border-box;
  padding: 15px 10px;
  overflow: auto;
  #nullData {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
.reportFormContainer {
  .el-row {
    display: flex;
    justify-content: center;
  }
  .el-table {
    width: 100%;
    height: 100%;
    /deep/ .has-gutter {
      .cell {
        font-weight: bold !important;
      }
    }
  }
  /deep/ .el-form-item__content {
    margin-left: 82px;
    margin-right: 10px;
  }

  /deep/ .el-divider--horizontal {
    margin: 14px 0 !important;
  }
  .el-date-editor {
    width: 100%;
    /deep/ .el-input__inner {
      padding-left: 30px;
      padding-right: 15px;
    }
  }
  .el-form-item {
    /*el-form样式*/
    /deep/ .el-form-item__label {
      width: 82px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      margin: 0 0 !important;
    }
  }
}
/deep/ .el-link.el-link--primary {
  font-size: 12px !important;
  color: #409eff !important;
}
/deep/ .el-link.el-link--primary:hover {
  color: #66b1ff !important;
}
</style>
