<template>
  <div class="contact" id="contactContainerID">
    <section id="contact">
      <div class="rgba-black-strong py-5">
        <div class="container">
          <div class="wow fadeIn">
            <h2 class="h1 text-white pt-5 pb-3 text-center">联系我们</h2>
            <!-- <p class="text-white px-5 mb-5 pb-3 lead text-center"></p> -->
            <div class="ContactContainer" v-if="clientWidth > clientHeight">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-row :gutter="5">
                    <el-col :span="8"><div style="text-align: right">邮编：</div> </el-col>
                    <el-col :span="16"> <span>350109 </span> </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row :gutter="5">
                    <el-col :span="8"><div style="text-align: right">电话：</div> </el-col>
                    <el-col :span="16"> <span>0591-83767516 </span> </el-col>
                  </el-row>
                </el-col>
                <el-col :span="8">
                  <el-row :gutter="5">
                    <el-col :span="8"><div style="text-align: right">传真：</div> </el-col>
                    <el-col :span="16"> <span>0591-83767516 </span> </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-row :gutter="5">
                    <el-col :span="8"><div style="text-align: right">邮箱：</div> </el-col>
                    <el-col :span="16"> <span>fzgddl@126.com </span> </el-col>
                  </el-row>
                </el-col>
                <el-col :span="16">
                  <el-row :gutter="5">
                    <el-col :span="4"><div style="text-align: right">地址：</div> </el-col>
                    <el-col :span="20"> <span>福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201</span> </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <div class="ContactContainer" v-else>
              <div id="contactItem">
                <div id="project">邮编：</div>
                <div>350109</div>
              </div>
              <div id="contactItem">
                <div id="project">电话：</div>
                <div>0591-83767516</div>
              </div>
              <div id="contactItem">
                <div id="project">传真：</div>
                <div>0591-83767516</div>
              </div>
              <div id="contactItem">
                <div id="project">邮箱：</div>
                <div>fzgddl@126.com</div>
              </div>
              <div id="contactItem">
                <div id="project">地址：</div>
                <div>福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201</div>
              </div>
            </div>
          </div>
          <div class="card mb-5 wow fadeInUp" data-wow-delay=".4s">
            <el-divider content-position="left">详细位置</el-divider>
            <!-- <div class="card-body p-5"> -->
            <baidu-map id="allmap" :scroll-wheel-zoom="true" @ready="mapReady">
              <!-- 比例尺控件，注册此组件才会在地图上显示比例尺 -->
              <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
              <!-- 缩放控件，注册此组件才会显示拖放进度 -->
              <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
              <!-- 信息窗体组件 -->
              <bm-info-window
                :title="`<span style='color:#3949ab;font-weight: bold;'>电力工业电力设备及线路器材质量检验测试中心</span>`"
                :position="{ lng: 119.219662, lat: 25.98232 }"
                :show="showInfoWindow"
                @close="infoWindowClose()"
              >
                <span>福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201<br />联系电话:0591-83767516</span>
              </bm-info-window>
            </baidu-map>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      point: "",
      showInfoWindow: false,
      map: null
    }
  },
  methods: {
    infoWindowClose() {
      let that = this
      this.$nextTick(() => {
        that.showInfoWindow = false
        that.createMarker(that.map)
      })
    },
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(119.219662, 25.98232);
      map.centerAndZoom(this.point, 15);
      this.map = map
      this.createMarker(this.map)
    },
    createInfoWindow(map) {
      const that = this
      // 信息框
      const titleOpts = {
        // width: 200,
        // height: 100,
        title: `<span style="color:#3949ab;font-weight: bold;">电力工业电力设备及线路器材质量检验测试中心</span>`
      }
      const infoWindow = new BMap.InfoWindow(`福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201<br />联系电话:0591-83767516`, titleOpts)
      infoWindow.removeEventListener('click', function () {
        that.createMarker(map)
      })
      return infoWindow
    },
    createMarker(map) {
      const that = this
      // 创建文本标注对象
      const opts = {
        position: this.point, // 指定文本标注所在的地理位置
        offset: new BMap.Size(0, 0) // 设置文本偏移量
      };
      let label = new BMap.Label('电力工业电力设备及线路器材质量检验测试中心', opts);
      // 自定义文本标注样式
      label.setStyle({
        // color: 'blue',
        cursor: 'pointer',
        padding: '0px 5px',
        borderRadius: '5px',
        borderColor: '#ccc',
        fontSize: '16px',
        height: '30px',
        lineHeight: '30px',
        fontFamily: '微软雅黑'
      });
      // 点坐标
      const marker = new BMap.Marker(this.point);
      map.addOverlay(marker);
      map.addOverlay(label);

      marker.addEventListener('click', async function () {
        map.clearOverlays();
        // map.openInfoWindow(await that.createInfoWindow(map), this.point); // 开启信息窗口
        that.showInfoWindow = true
        // map.setCenter(new BMap.Point(119.219662, 25.98232));
        map.centerAndZoom(new BMap.Point(119.219662, 25.98232), 15);
      });
      // 点标记添加点击事件
      label.addEventListener('click', async function () {
        map.clearOverlays();
        // map.openInfoWindow(await that.createInfoWindow(map), this.point); // 开启信息窗口
        that.showInfoWindow = true
        // map.setCenter(new BMap.Point(119.219662, 25.98232));
        map.centerAndZoom(new BMap.Point(119.219662, 25.98232), 15);
      });
    }
  },
  computed: {
    // 浏览器宽度
    clientWidth() {
      return Number(this.$store.state.clientWidth)
    },
    // 浏览器高度
    clientHeight() {
      return Number(this.$store.state.clientHeight)
    }
  }
}
</script>
<style lang="less" scoped>
#contactItem {
  width: 100%;
  display: flex;
  justify-content:center;
  #project {
    min-width: 60px;
    text-align: right;
  }
}
.contact {
  width: 100%;
}
.ContactContainer .el-row {
  /* padding-left:30% */
  display: flex;
  justify-content: center;
}
.ContactContainer {
  color: #fff;
  font-size: 18px;
}
/* 设定地图的大小 */
#allmap {
  height: 700px;
  width: 100%;
  margin: 0 auto;
}
/deep/ .is-left {
  left: 0 !important;
}

/deep/ .el-divider--horizontal {
  margin: 14px 0 !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
}
</style>
