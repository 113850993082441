<template>
  <div class="HomeEntrust">
    <HomeHeaderFixed isActive="Entrust" />
    <div class="entrustContainer">
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane name="main">
          <span slot="label"><i class="el-icon-notebook-2"></i> 检验委托书</span>
          <div class="formContainer">
            <div id="buttonGroup" :style="isMobile ? 'justify-content: center' : ''">
              <el-button type="primary" size="small" @click="submit" icon="el-icon-check">提交</el-button>
              <el-button size="small" icon="el-icon-refresh-right" @click="reset">重置</el-button>
            </div>
            <el-tabs class="contentTabs" v-model="activeNameContent">
              <el-tab-pane label="基本信息" style="height: 100%" name="main">
                <div id="form" v-if="!isMobile">
                  <el-form
                    :model="form"
                    ref="FormRef"
                    :label-position="'left'"
                    :label-width="globalLableWidth"
                    :validate-on-rule-change="false"
                    :rules="rules"
                    size="mini"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-form-item :class="computedHeight('委托方全称', true)" label="委托方全称" prop="et_customer">
                          <el-input v-model="form.et_customer" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item :class="computedHeight('产品名称', true)" label="产品名称" prop="et_productname">
                          <el-input v-model="form.et_productname" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item :class="computedHeight('检测类别', true)" label="检测类别" prop="et_jclb">
                          <el-input v-model="form.et_jclb" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :class="computedHeight('是否保密')" label="是否保密" prop="et_ifbm">
                          <el-select clearable v-model="form.et_ifbm" placeholder="请选择">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item
                          :class="computedHeight('要求检测完成日期', true)"
                          label="要求检测完成日期"
                          prop="et_jcwcDate"
                        >
                          <el-input v-model="form.et_jcwcDate" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          :class="computedHeight('样品特性状态观察记事', true)"
                          label="样品特性状态观察记事"
                          prop="et_yptxzt"
                        >
                          <el-input v-model="form.et_yptxzt" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-divider content-position="left">样品（包装或铭牌）识别</el-divider>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item :class="computedHeight('生产单位', true)" label="生产单位" prop="et_yp_scdw">
                          <el-input v-model="form.et_yp_scdw" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :class="computedHeight('样品数量', true)" label="样品数量" prop="et_yp_count">
                          <el-input v-model="form.et_yp_count" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :class="computedHeight('生产日期', true)" label="生产日期" prop="et_yp_scdate">
                          <el-input v-model="form.et_yp_scdate" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item :class="computedHeight('商标')" label="商标" prop="et_yp_sb">
                          <el-input v-model="form.et_yp_sb" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :class="computedHeight('包装描述')" label="包装描述" prop="et_yp_bzms">
                          <el-input v-model="form.et_yp_bzms" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :class="computedHeight('批号/编号')" label="批号/编号" prop="et_yp_pici">
                          <el-input v-model="form.et_yp_pici" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-divider content-position="left">委托信息</el-divider>
                        <el-form-item
                          :class="computedHeight('委托人地址', true)"
                          label="委托人地址"
                          prop="et_wtraddress"
                        >
                          <el-input v-model="form.et_wtraddress" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('邮政编码', true)" label="邮政编码" prop="et_yzbm">
                          <el-input v-model="form.et_yzbm" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('传真')" label="传真" prop="et_fax">
                          <el-input v-model="form.et_fax" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('联系人及电话', true)" label="联系人及电话" prop="et_tel">
                          <el-input v-model="form.et_tel" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                        <!-- <el-form-item :class="computedHeight('检测费用')" label="检测费用" prop="clientMoney">
                          <el-input v-model="form.clientMoney" autocomplete="off" placeholder="输入" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item :class="computedHeight('委托人签字')" label="委托人签字" prop="et_sign">
                          <el-input v-model="form.et_sign" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-divider content-position="left">受理信息</el-divider>
                        <el-form-item :class="computedHeight('承检单位地址')" label="承检单位地址">
                          <el-input
                            v-model="form.acceptAddress"
                            autocomplete="off"
                            placeholder="输入"
                            readonly
                          ></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('邮政编码')" label="邮政编码">
                          <el-input
                            v-model="form.acceptPostCode"
                            autocomplete="off"
                            placeholder="输入"
                            readonly
                          ></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('传真')" label="传真">
                          <el-input v-model="form.acceptFax" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('联系电话')" label="联系电话">
                          <el-input v-model="form.acceptTel" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                        <el-form-item :class="computedHeight('支付方式')" label="支付方式">
                          <el-input v-model="form.payMethod" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                        <!-- <el-form-item :class="computedHeight('受理人签字')" label="受理人签字" prop="acceptSign">
                          <el-input v-model="form.acceptSign" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item> -->
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('备注', true)" label="备注" prop="et_Memo">
                          <el-input
                            type="textarea"
                            v-model="form.et_Memo"
                            autocomplete="off"
                            placeholder="输入"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <div id="phoneForm" v-else>
                  <el-form
                    :model="form"
                    ref="phoneFormRef"
                    :label-position="'left'"
                    :label-width="globalLableWidth"
                    :validate-on-rule-change="false"
                    :rules="rules"
                    size="mini"
                  >
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('委托方全称', true)" label="委托方全称" prop="et_customer">
                          <el-input v-model="form.et_customer" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('产品名称', true)" label="产品名称" prop="et_productname">
                          <el-input v-model="form.et_productname" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('检测类别', true)" label="检测类别" prop="et_jclb">
                          <el-input v-model="form.et_jclb" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('是否保密')" label="是否保密" prop="et_ifbm">
                          <el-select clearable v-model="form.et_ifbm" placeholder="请选择">
                            <el-option label="是" :value="true"></el-option>
                            <el-option label="否" :value="false"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          :class="computedHeight('要求检测完成日期', true)"
                          label="要求检测完成日期"
                          prop="et_jcwcDate"
                        >
                          <el-input v-model="form.et_jcwcDate" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          :class="computedHeight('样品特性状态观察记事', true)"
                          label="样品特性状态观察记事"
                          prop="et_yptxzt"
                        >
                          <el-input
                            type="textarea"
                            v-model="form.et_yptxzt"
                            autocomplete="off"
                            placeholder="输入"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('备注', true)" label="备注" prop="et_Memo">
                          <el-input
                            type="textarea"
                            v-model="form.et_Memo"
                            autocomplete="off"
                            placeholder="输入"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-divider content-position="left">样品（包装或铭牌）识别</el-divider>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('生产单位', true)" label="生产单位" prop="et_yp_scdw">
                          <el-input v-model="form.et_yp_scdw" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('样品数量', true)" label="样品数量" prop="et_yp_count">
                          <el-input v-model="form.et_yp_count" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('生产日期', true)" label="生产日期" prop="et_yp_scdate">
                          <el-input v-model="form.et_yp_scdate" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('商标')" label="商标" prop="et_yp_sb">
                          <el-input v-model="form.et_yp_sb" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('包装描述')" label="包装描述" prop="et_yp_bzms">
                          <el-input v-model="form.et_yp_bzms" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('批号/编号')" label="批号/编号" prop="et_yp_pici">
                          <el-input v-model="form.et_yp_pici" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-divider content-position="left">委托信息</el-divider>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          :class="computedHeight('委托人地址', true)"
                          label="委托人地址"
                          prop="et_wtraddress"
                        >
                          <el-input v-model="form.et_wtraddress" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('邮政编码', true)" label="邮政编码" prop="et_yzbm">
                          <el-input v-model="form.et_yzbm" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('传真')" label="传真" prop="et_fax">
                          <el-input v-model="form.et_fax" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('联系人及电话', true)" label="联系人及电话" prop="et_tel">
                          <el-input v-model="form.et_tel" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('委托人签字')" label="委托人签字" prop="et_sign">
                          <el-input v-model="form.et_sign" autocomplete="off" placeholder="输入"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-divider content-position="left">受理信息</el-divider>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('承检单位地址')" label="承检单位地址">
                          <el-input
                            v-model="form.acceptAddress"
                            autocomplete="off"
                            placeholder="输入"
                            readonly
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('邮政编码')" label="邮政编码">
                          <el-input
                            v-model="form.acceptPostCode"
                            autocomplete="off"
                            placeholder="输入"
                            readonly
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('传真')" label="传真">
                          <el-input v-model="form.acceptFax" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('联系电话')" label="联系电话">
                          <el-input v-model="form.acceptTel" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item :class="computedHeight('支付方式')" label="支付方式">
                          <el-input v-model="form.payMethod" autocomplete="off" placeholder="输入" readonly></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-tab-pane>
              <!-- (双击单元格编辑内容) -->
              <el-tab-pane label="明细信息" name="detail">
                <div v-if="!isMobile">
                  <vxe-grid
                    border
                    size="mini"
                    :auto-resize="true"
                    :resizable="true"
                    show-overflow
                    align="center"
                    keep-source
                    ref="xTable"
                    :edit-rules="validRules"
                    :height="tableHeight"
                    :toolbar="tableToolbar"
                    @toolbar-button-click="toolbarButtonClickEvent"
                    :data="form.detailList"
                    :edit-config="{ trigger: 'click', mode: 'row' }"
                    :mouse-config="{
                      selected: true,
                    }"
                  >
                    <vxe-table-column type="seq" width="50"></vxe-table-column>
                    <vxe-table-column field="delete" title="删除" width="50" align="center">
                      <template slot-scope="scope">
                        <i
                          @click="removeRowForTable(scope.row, $refs.xTable)"
                          style="color: red"
                          class="el-icon-delete-solid"
                        ></i>
                      </template>
                    </vxe-table-column>
                    <vxe-table-column
                      field="xhao"
                      title="型号规格"
                      :edit-render="{ name: 'input' }"
                      align="center"
                    ></vxe-table-column>
                    <vxe-table-column
                      field="jcxm"
                      title="检测项目"
                      :edit-render="{ name: 'input' }"
                      align="center"
                    ></vxe-table-column>
                    <vxe-table-column
                      field="jcyj"
                      title="检测依据"
                      :edit-render="{ name: 'input' }"
                      align="center"
                    ></vxe-table-column>
                    <vxe-table-column
                      field="jcff"
                      title="检验方法"
                      :edit-render="{ name: 'input' }"
                      align="center"
                    ></vxe-table-column>
                    <vxe-table-column
                      field="memo"
                      title="备注"
                      :edit-render="{ name: 'input' }"
                      align="center"
                    ></vxe-table-column>
                  </vxe-grid>
                </div>
                <div id="phoneDetail" :style="{ height: tableHeight + 'px' }" v-else>
                  <div style="display: flex; justify-content: center; width: 100%">
                    <el-button size="mini" @click="handleAddPhoneDetail">新增明细</el-button>
                  </div>
                  <div :style="{ height: tableHeight - 28 + 'px' }">
                    <div id="nullData" v-show="form.detailList.length === 0">
                      <img src="../../assets/nullData.png" alt="暂无内容" />
                    </div>
                    <div id="phoneDetailItem" v-for="(item, index) in form.detailList" :key="index">
                      <div id="deleteDetailBtn" @click="removePhoneDetailForTable(index)">
                        <i class="el-icon-delete" />
                      </div>
                      <el-form
                        :model="item"
                        :label-position="'left'"
                        :label-width="globalLableWidth"
                        :validate-on-rule-change="false"
                        size="mini"
                      >
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="型号规格" prop="xhao">
                              <el-input v-model="item.xhao" autocomplete="off" placeholder="输入"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="检测项目" prop="jcxm">
                              <el-input v-model="item.jcxm" autocomplete="off" placeholder="输入"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="检测依据" prop="jcyj">
                              <el-input v-model="item.jcyj" autocomplete="off" placeholder="输入"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="检验方法" prop="jcff">
                              <el-input v-model="item.jcff" autocomplete="off" placeholder="输入"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="备注" prop="memo">
                              <el-input v-model="item.memo" autocomplete="off" placeholder="输入"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 手机端弹窗 -->
    <PhoneMessageBox
      ref="PhoneMessageBox"
      :modalTitle="phoneModalTitle"
      :confirTitle="phoneConfirTitle"
      @sureBackConfir="sureBackConfir()"
    />
    <HomeFooter />
  </div>
</template>
<script>
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
import PhoneMessageBox from '@/components/modal/Entrust/PhoneMessageBox.vue'
export default {
  components: {
    HomeHeaderFixed,
    HomeFooter,
    PhoneMessageBox
  },
  data() {
    return {
      activeName: 'main',
      activeNameContent: 'main',
      globalLableWidth: '90px',
      // tabel工具栏
      tableToolbar: {
        buttons: [
          {
            code: 'insertOne',
            name: '新增1行'
          },
          {
            code: 'insertTen',
            name: '新增10行'
          }
        ]
      },
      rules: {
        et_customer: [
          { required: true, message: '请输入委托方全称', trigger: 'change' }
        ],
        et_jclb: [
          { required: true, message: '请输入检测类别', trigger: 'change' }
        ],
        et_ifbm: [
        ],
        et_productname: [
          { required: true, message: '请输入产品名称', trigger: 'change' }
        ],
        et_yp_scdw: [
          { required: true, message: '请输入生产单位', trigger: 'change' }
        ],
        et_yp_scdate: [
          { required: true, message: '请输入生产日期', trigger: 'change' }
        ],
        et_yp_sb: [
        ],
        et_yp_bzms: [
        ],
        et_yp_pici: [
        ],
        et_jcwcDate: [
          { required: true, message: '请输入要求检测完成日期', trigger: 'change' }
        ],
        et_yptxzt: [
          { required: true, message: '请输入样品特性状态观察记事', trigger: 'change' }
        ],
        et_wtraddress: [
          { required: true, message: '请输入委托人地址', trigger: 'change' }
        ],
        et_yzbm: [
          { required: true, message: '请输入邮政编码', trigger: 'change' }
        ],
        et_fax: [
        ],
        et_tel: [
          { required: true, message: '请输入联系人及电话', trigger: 'change' }
        ],
        // clientMoney: [
        // ],
        et_sign: [
        ],
        acceptSign: [
        ],
      },
      form: {
        et_customer: '',
        et_jclb: '',
        et_ifbm: false,
        et_productname: '',
        et_yp_scdw: '',
        et_yp_count: '',
        et_yp_scdate: '',
        et_yp_sb: '',
        et_yp_bzms: '',
        et_yp_pici: '',
        et_jcwcDate: '',
        et_yptxzt: '',
        et_wtraddress: '',
        et_yzbm: '',
        et_fax: '',
        et_tel: '',
        // clientMoney: '',
        et_sign: '',
        acceptAddress: '福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201',
        acceptPostCode: '350109',
        acceptFax: '0591-83767516',
        acceptTel: '0591-83767516',
        payMethod: '银行转账',
        detailList: []
      },
      errIndex: [],
      // 明细验证规则
      validRules: {},
      // 手机端确认弹窗标题
      phoneModalTitle: '',
      // 手机端确认弹窗提示内容
      phoneConfirTitle: '',
      // 手机端确认弹窗类型
      phoneConfirType: '',
      // 手机端新增时，缓存对应form(不直接操作form)
      phoneAddForm: null,
      // 手机端删除明细时，缓存对应索引位置
      phoneDeleteItemIndex: -1
    }
  },
  mounted() {
  },
  computed: {
    tableHeight() {
      if (this.activeNameContent === 'detail') {
        return document.body.clientHeight - 370
      } else {
        return 100
      }
    },
    // 是否为手机端
    isMobile() {
      return this.$store.state.isMobile
    }
  },
  methods: {
    // 手机弹窗确认
    sureBackConfir() {
      const that = this
      switch (this.phoneConfirType) {
        case 'add':
          that.handleAdd(this.phoneAddForm)
          break
        case 'deleteItem':
          this.form.detailList.splice(this.phoneDeleteItemIndex, 1)
          break
      }
    },
    // 插入明细 - 手机端
    handleAddPhoneDetail() {
      this.form.detailList.unshift({
        xhao: '',
        jcxm: '',
        jcyj: '',
        jcff: '',
        memo: ''
      })
    },
    // 保存
    submit() {
      this.validEnter().then(async result => {
        if (result) {
          this.add()
        }
      })
    },
    // 新增单据
    add() {
      let that = this
      let form = { ...that.form }
      // let insertRecords = that.$refs.xTable.getInsertRecords()
      let tableData = []
      if (!this.isMobile) {
        tableData = that.$refs.xTable.getTableData().fullData
        form.detailList = tableData
      }
      form = this.removeObjectNull(form)
      form.detailList = that.removeArrayNull(form.detailList)
      let tooltip = ''
      if (form.detailList.length === 0) {
        tooltip += '且检验明细中没有内容,'
      }
      if (!this.isMobile) {
        this.$confirm('委托一旦提交就不可修改,' + tooltip + ' 是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.handleAdd(form)
        })
      } else {
        this.phoneConfirType = 'add'
        this.phoneAddForm = form
        this.phoneModalTitle = '提示'
        this.phoneConfirTitle = '委托一旦提交就不可修改,' + tooltip + ' 是否继续提交?'
        this.$refs.PhoneMessageBox.formVisible = true
      }
    },
    // 确认新增
    async handleAdd(form) {
      const { data: res } = await this.$http.post('/Company/Entrust/both', form)
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.$message.success('提交委托成功!')
      this.activeNameContent = 'main'
      this.reset()
    },
    // 重置
    reset() {
      this.form = {
        et_customer: '',
        et_jclb: '',
        et_ifbm: false,
        et_productname: '',
        et_yp_scdw: '',
        et_yp_count: '',
        et_yp_scdate: '',
        et_yp_sb: '',
        et_yp_bzms: '',
        et_yp_pici: '',
        et_jcwcDate: '',
        et_yptxzt: '',
        et_wtraddress: '',
        et_yzbm: '',
        et_fax: '',
        et_tel: '',
        // clientMoney: '',
        et_sign: '',
        acceptAddress: '福建省福州市高新区南屿旗山路1号融侨旗山观山府4号地块201',
        acceptPostCode: '350109',
        acceptFax: '0591-83767516',
        acceptTel: '0591-83767516',
        payMethod: '银行转账',
        acceptSign: '',
        detailList: []
      }
      if (!this.isMobile) {
        this.$refs.xTable.remove()
      }
      this.$nextTick(() => {
        if (this.isMobile) {
          this.$refs.phoneFormRef.clearValidate()
          document.getElementById('phoneForm').scrollTop = 0
        } else {
          this.$refs.FormRef.clearValidate()
        }
      })
    },
    // 判断字长度最多十个字
    computedHeight(str, require = false, labelWidth) {
      const width = labelWidth ? labelWidth : this.globalLableWidth.split('px')[0]
      const count = Math.floor((Number(width) / 15)) + 1
      if (require) {
        if (str.length >= (count - 1)) {
          return 'doubleFontClass'
        }
      } else {
        if (str.length > count) {
          return 'doubleFontClass'
        }
      }
      return ''
    },
    // table工具栏方法
    toolbarButtonClickEvent({ code }, event) {
      switch (code) {
        case 'insertOne':
          this.insertEvent()
          break
        case 'insertTen':
          this.insertTenEvent()
          break
      }
    },
    // 新增一行
    insertEvent() {
      let record = {}
      this.$refs.xTable.insertAt(record, -1)
    },
    // 新增10行
    insertTenEvent() {
      let record = {}
      for (var i = 0; i < 10; i++) {
        this.$refs.xTable.insertAt(record, -1)
      }
    },
    // 验证
    async validEnter() {
      const beforeTab = this.activeNameContent + ''
      // 主表部分校验
      this.activeNameContent = 'main'
      let flag = true
      if (this.isMobile) {
        await this.$refs.phoneFormRef.validate().then(
          res => {
            flag = res
          },
          err => {
            flag = err
          }
        )
      } else {
        await this.$refs.FormRef.validate().then(
          res => {
            flag = res
          },
          err => {
            flag = err
          }
        )
      }
      // 失败停留在表单页面
      if (!flag) {
        this.$message.error('验证失败,请核对输入数据')
        return false
      }
      // 切换至明细页面校验
      this.activeNameContent = 'detail'
      if (this.isMobile) {
        this.removeEmptyForPhoneDetail(this.form.detailList)
      } else {
        this.errIndex = []
        this.removeEmptyForTable(this.$refs.xTable)
        const errMap = await this.$refs.xTable
          .fullValidate()
          .catch(errMap => errMap)
        if (errMap) {
          Object.values(errMap).forEach(errList => {
            errList.forEach(params => {
              let { row, columnIndex, rules } = params
              rules.forEach(rule => {
                this.errIndex.push({
                  _XID: row._XID,
                  columnIndex: columnIndex
                })
              })
            })
          })
          if (this.errIndex.length > 0) {
            flag = false
          }
        }
        if (!flag) {
          this.$message.error('验证失败,请核对输入数据')
          return false
        }
      }
      // 校验没问题就返回原先的页面
      this.activeNameContent = beforeTab
      return flag
    },
    // 清除form的null
    removeObjectNull(obj) {
      let copy = JSON.parse(JSON.stringify(obj))
      Object.keys(copy).forEach(item => {
        if (!copy[item]) {
          delete copy[item]
        }
      })
      return copy
    },
    // 清空明细列表中每行的null
    removeArrayNull(array) {
      for (let obj of array) {
        Object.keys(obj).forEach(item => {
          if (!obj[item]) {
            delete obj[item]
          }
        })
      }
      return array
    },
    // 删除空行 ==> 手机端的item
    removeEmptyForPhoneDetail(list = []) {
      for (let i = list.length - 1; i >= 0; i--) {
        let ifRemove = true
        for (let key in list[i]) {
          if (list[i][key] !== null && list[i][key] !== '') {
            ifRemove = false
            break
          }
        }
        if (ifRemove) {
          list.splice(i, 1)
        }
      }
    },
    // 清空明细列表的空行
    removeEmptyForTable(table, defaultArray = []) {
      let tableData = table.getTableData().fullData
      for (let row of tableData) {
        let ifRemove = true
        for (let key in row) {
          if (key !== '_XID' && !defaultArray.includes(key)) {
            if (row[key] !== null) {
              ifRemove = false
              break
            }
          }
        }
        if (ifRemove) {
          table.remove(row)
        }
      }
    },
    // 删除明细
    removeRowForTable(row, table) {
      this.$confirm('是否要删除此行？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          table.remove(row)
        })
        .catch(() => { })
    },
    // 删除手机端明细
    removePhoneDetailForTable(index) {
      this.phoneConfirType = 'deleteItem'
      this.phoneDeleteItemIndex = index
      this.phoneModalTitle = '提示'
      this.phoneConfirTitle = '是否要删除此条明细？'
      this.$refs.PhoneMessageBox.formVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
@titleHeight: 5rem;
@footerHeight: 66.61px;
@pageBackground: #f5f5f5;
// .el-form-item {
//   margin-bottom: 18px !important;
// }
/deep/ .el-form-item {
  margin-bottom: 18px !important;
}
.HomeEntrust {
  height: 100%;
  background: @pageBackground;
}
.entrustContainer {
  padding-top: @titleHeight;
  height: calc(100% - @footerHeight);
  .el-tabs {
    margin: 30px auto;
    width: 75%;
    height: calc(100% - 60px);
    /deep/ .el-tabs__content {
      padding: 0;
    }
  }
  .formContainer {
    height: calc(100vh - @titleHeight - @footerHeight - 60px - 39px);
    overflow: auto;
    #buttonGroup {
      height: 40px;
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    #form {
      width: 100%;
      .el-col {
        padding: 0 10px;
      }
    }
    #phoneForm {
      width: 100%;
      height: calc(100vh - @titleHeight - 30px - @footerHeight - 60px - 40px - 40px - 40px);
      overflow: auto;
    }
    #phoneDetail {
      overflow: auto;
      #nullData {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 120px;
          height: 120px;
        }
      }
      #phoneDetailItem {
        position: relative;
        width: calc(100% - 2px);
        border: 1px dashed #666;
        border-radius: 5px;
        margin-top: 5px;
        box-sizing: border-box;
        padding: 5px;
        .el-form-item {
          margin: 1px 0 0 0 !important;
        }
        #deleteDetailBtn {
          z-index: 999;
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          border-radius: 0 0 30px 0;
          background: red;
          font-size: 12px;
          color: white;
          box-sizing: border-box;
          padding: 5px 0 0 7px;
        }
      }
    }
  }

  /deep/ .el-form-item__content {
    margin-left: 82px;
    margin-right: 10px;
  }
  /deep/ .is-left {
    left: 0 !important;
  }

  /deep/ .el-divider--horizontal {
    margin: 14px 0 !important;
  }
  .el-date-editor {
    width: 100%;
    /deep/ .el-input__inner {
      padding-left: 30px;
      padding-right: 15px;
    }
  }
  .el-form-item {
    /*el-form样式*/
    /deep/ .el-form-item__label {
      width: 82px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      margin: 0 0 !important;
    }
  }
  .doubleFontClass {
    /*el-form样式*/
    /deep/ .el-form-item__label {
      width: 82px;
      color: rgb(0, 0, 0);
      font-size: 12px;
      text-align: right;
      line-height: 14px !important;
      margin: 0 0 !important;
    }
  }
}
@colHeight: 24px;
.contentTabs {
  padding: 0 10px !important;
  margin: 0 !important;
  width: 100% !important;
}
/deep/ .col--center .vxe-cell {
  height: @colHeight;
  line-height: @colHeight;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .vxe-header--column {
  font-size: 12px;
  padding: 3px 0 !important;
}
/deep/ .vxe-body--column {
  font-size: 12px;
  height: @colHeight !important;
  padding: 3px 0 !important;
}
/deep/ .vxe-body--row {
  height: @colHeight !important;
}
/deep/ .vxe-default-input {
  height: @colHeight !important;
}
</style>