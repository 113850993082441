<template>
  <div class="HomeNews">
    <HomeHeaderFixed isActive="Download" />
    <div class="newsContainer" v-if="!isMobile">
      <div class="newsContent">
        <div class="newsLeft">
          <div id="newsLeftWindow">
            <div id="newsLeftTitle" style="font-weight: 500">下载专区</div>
            <div id="newsLeftContent">
              <ul>
                <li
                  :class="item === '全部分类' ? 'nodeIsAll' : ''"
                  v-for="(item, index) of treeNodeList"
                  :key="index"
                  :style="currentNode === item ? 'border-left: 5px #4e62e9 solid;padding-left: 5px;' : ''"
                  @click="handleClick(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="newsRight">
          <div id="newsRightWindow">
            <div id="newsRightTitle" style="font-weight: 500">
              <div id="titleBlock"></div>
              {{ currentNode }}
            </div>
            <div id="newsRightContent">
              <div id="hasData" v-if="currentNodeList.length > 0">
                <ul>
                  <div v-for="(item, index) of currentNodeList" :key="index" style="width: 100%">
                    <el-divider v-if="currentNode === '全部分类' && handleDivider(index)">{{
                      item.fileType
                    }}</el-divider>
                    <li @click="handleDownload(item)">
                      <span id="title">{{ titleString(item.fileDispalyName) }}</span>
                      <span id="date">{{ item.uploadTime }}</span>
                    </li>
                  </div>
                </ul>
              </div>
              <div id="notData" v-else>
                <img src="../../assets/nullData.png" style="width: 120px; height: 120px" alt="暂无数据" />
              </div>
            </div>
            <div id="newsRightFooter">
              <div id="pageMsg">第{{ pageParms.pageIndex }}页 / 共{{ pageParms.pageCount }}页</div>
              <el-button
                size="mini"
                icon="el-icon-d-arrow-left"
                @click="handleToHeader()"
                :disabled="pageParms.pageIndex === 1"
                >首页</el-button
              >
              <el-button size="mini" icon="el-icon-back" @click="handleToUpPage()" :disabled="pageParms.pageIndex === 1"
                >上一页</el-button
              >
              <el-button
                size="mini"
                icon="el-icon-right"
                @click="handleToNextPage()"
                :disabled="pageParms.pageIndex === pageParms.pageCount"
                >下一页</el-button
              >
              <el-button
                size="mini"
                icon="el-icon-d-arrow-right"
                :disabled="pageParms.pageIndex === pageParms.pageCount"
                @click="handleTofooter()"
                >尾页</el-button
              >
              <el-input-number
                v-model="pageParms.jumpPage"
                size="mini"
                :min="1"
                :max="pageParms.pageCount"
                :controls="false"
              >
              </el-input-number>
              <el-button size="mini" @click="pageJump()">跳转</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phoneNewsContainer" v-else>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick(activeName)">
        <el-tab-pane v-for="(item, index) in treeNodeList" :key="index" :name="item" :label="item">
          <div class="phoneNewsBodyContainer" :style="{ height: phoneNewsBodyHeight }">
            <div id="hasData" v-if="currentNodeList.length > 0">
              <ul>
                <div v-for="(item, index) of currentNodeList" :key="index" style="width: 100%">
                  <el-divider v-if="currentNode === '全部分类' && handleDivider(index)">{{ item.fileType }}</el-divider>
                  <li @click="handleDownload(item)">
                    <span id="title">{{ titleString(item.fileDispalyName) }}</span>
                  </li>
                </div>
              </ul>
            </div>
            <div id="notData" v-else>
              <img src="../../assets/nullData.png" style="width: 120px; height: 120px" alt="暂无数据" />
            </div>
          </div>
          <div class="phonePageFooter">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="pageParms.pageIndex"
              :page-size="pageParms.pageSize"
              layout="total, prev, pager, next"
              :total="pageParms.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <HomeFooter />
  </div>
</template>
<script>
import HomeHeaderFixed from './HomeHeaderFixed.vue'
import HomeFooter from './HomeFooterFixed.vue'
export default {
  components: {
    HomeHeaderFixed,
    HomeFooter
  },
  data() {
    return {
      pageParms: {
        pageIndex: 1, // 当前页
        pageSize: 14, // 一页的数量
        pageCount: 1, // 最多页数'
        jumpPage: 1, // 跳转页
        total: 0
      },
      treeNodeList: [
        '全部分类',
        '标准下载',
        '产品细则',
        '申报表格',
        '其他',
      ],
      currentNode: '全部分类',
      currentNodeList: [],
      activeName: '全部分类'
    }
  },
  mounted() {
    this.BindData()
  },
  methods: {
    // 手机端的分页事件
    handleCurrentChange(e) {
      this.pageParms.jumpPage = e
      this.pageJump('phone')
    },
    // 获取文件后缀名
    getTitleString(fileName) {
      let fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
      return '.' + fileType
    },
    // 下载
    async handleDownload(item) {
      if (!item.id) {
        this.$message.error('下载标识无效!')
        return
      }
      const { data: res } = await this.$http.get('/Company/YKFilesUpload/download', {
        params: {
          id: item.id
        },
        responseType: 'blob'
      })
      // 下载文件流
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(res)
      link.download = item.fileDispalyName + this.getTitleString(item.fileName)
      link.click()
    },
    // 跳转
    pageJump(type) {
      if (this.pageParms.jumpPage >= 1 && this.pageParms.jumpPage <= this.pageParms.pageCount && (this.pageParms.jumpPage !== this.pageParms.pageIndex || type === 'phone')) {
        this.pageParms.pageIndex = this.pageParms.jumpPage
        this.BindData()
      }
    },
    // 上一页
    handleToUpPage() {
      if ((this.pageParms.pageIndex - 1) >= 1) {
        this.pageParms.pageIndex -= 1
        this.BindData()
      }
    },
    // 下一页
    handleToNextPage() {
      if ((this.pageParms.pageIndex + 1) <= this.pageParms.pageCount) {
        this.pageParms.pageIndex += 1
        this.BindData()
      }
    },
    // 首页
    handleToHeader() {
      this.pageParms.pageIndex = 1
      this.BindData()
    },
    // 尾页
    handleTofooter() {
      this.pageParms.pageIndex = this.pageParms.pageCount
      this.BindData()
    },
    // 绑定数据
    async BindData() {
      const { data: res } = await this.$http.post('/Company/YKFilesUpload/list', {
        pageIndex: this.pageParms.pageIndex,
        pageSize: this.pageParms.pageSize,
        fileType: this.currentNode === '全部分类' ? '' : this.currentNode
      })
      if (!res.isSuccess) {
        this.$message.error(res.errorMessage)
        return false
      }
      this.currentNodeList = res.data.rows
      this.pageParms.pageCount = Math.ceil(res.data.totals / this.pageParms.pageSize)
      this.pageParms.total = res.data.totals
    },
    // 点击节点
    handleClick(item) {
      this.pageParms = {
        pageIndex: 1, // 当前页
        pageSize: 14, // 一页的数量
        pageCount: 1, // 最多页数'
        jumpPage: 1 // 跳转页
      }
      this.currentNode = item
      this.BindData()
    },
    // 处理title过长的字符串
    titleString(str) {
      return str.length > 48 ? str.substr(0, 49) + '...' : str
    },
    // 判断是否需要分割线
    handleDivider(index) {
      if (index === 0 && this.currentNodeList.length > 0) {
        return true
      }
      if (this.currentNodeList?.[index]?.['fileType'] && this.currentNodeList?.[index - 1]?.['fileType']) {
        if (this.currentNodeList[index]['fileType'] !== this.currentNodeList[index - 1]['fileType']) {
          return true
        }
      }
      return false
    }
  },
  computed: {
    // 浏览器高度
    phoneNewsBodyHeight() {
      return ((Number(this.$store.state.clientHeight) - 67 - 85) * 0.85) - 40 - 37 - 30 + 'px'
    },
    // 是否为手机端
    isMobile() {
      return this.$store.state.isMobile
    }
  }
}
</script>
<style lang="less" scoped>
.HomeNews {
  height: 100%;
  background: #f5f5f5;
}
.newsContainer {
  padding-top: 5rem;
  height: calc(100% - 66.61px);
  overflow: auto;
}
.phoneNewsContainer {
  padding-top: 85px;
  height: calc(100% - 66.61px);
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  .el-tabs {
    height: 85%;
    width: calc(100% - 20px);
    margin-left: 10px;
    .phoneNewsBodyContainer {
      width: 100%;
      overflow: auto;
    }
    .phonePageFooter {
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
.newsContent {
  width: 75%;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
}
.newsLeft {
  width: 260px;
  /* width: calc(25% - 40px); */
  margin-right: 25px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.newsRight {
  height: 100%;
  width: calc(100% - 300px);
}
#newsLeftWindow {
  height: 280px;
  width: 80%;
  /* background: blueviolet; */
  border-radius: 5px;
  border: 1px solid #ada8aa;
}
#newsLeftWindow #newsLeftTitle {
  height: 50px;
  background: #e3dede;
  border-radius: 5px 5px 0 0;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  z-index: 100;
  border-bottom: 1px solid #ada8aa;
}
.nodeIsAll {
  font-weight: bold;
}
#newsLeftWindow #newsLeftContent {
  border-radius: 0 0 5px 5px;
  padding: 10px;
  padding-left: 30px;
}
#newsLeftContent ul,
.phoneNewsBodyContainer ul {
  padding-left: 0px;
}
#newsLeftContent li,
.phoneNewsBodyContainer li {
  margin-bottom: 8px;
  font-size: 14px;
  list-style: none;
  cursor: pointer;
}
#newsLeftContent li:hover,
.phoneNewsBodyContainer li:hover {
  color: #999;
  transition: all 0.3s;
}
#newsRightWindow {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #ada8aa;
}
#newsRightWindow #newsRightTitle {
  height: 50px;
  background: #e3dede;
  border-radius: 5px 5px 0 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #ada8aa;
}
#titleBlock {
  width: 5px;
  height: 20px;
  background: #3949ab;
  margin-right: 10px;
}
#newsRightWindow #newsRightContent {
  height: calc(100% - 50px - 60px);
  width: 100%;
  padding: 10px 0;
}
#newsRightWindow #newsRightFooter {
  height: 60px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
}
#newsRightFooter .el-input-number {
  margin: 0;
  padding: 0;
  width: 50px;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 10px;
  border: 1px solid #dcdfe6;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner {
  width: 48px;
  height: 38px;
  padding: 0 5px;
  border: 0;
  border-radius: 5px;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner:hover {
  border: 0;
}
/deep/ #newsRightFooter .el-input-number .el-input__inner:focus {
  border: 0;
  box-shadow: 0 0 0 0;
}
#newsRightFooter #pageMsg {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;
  border: 1px solid #dcdfe6;
  background: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #606266;
  font-weight: 500;
}
#notData {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#hasData {
  height: 100%;
  overflow: auto;
  padding: 5px 0;
}
/*滚动条整体样式*/
#hasData::-webkit-scrollbar {
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
/*滚动条里面小方块*/
#hasData::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #cecccc;
}
/*滚动条里面轨道*/
#hasData::-webkit-scrollbar-track {
  box-shadow: 0 0 0 0;
  border-radius: 10px;
  background: #f5f5f5;
}
#hasData ul {
  /* height: 100%; */
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#hasData ul li {
  height: 44px;
  width: 100%;
  padding: 0 30px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdfe6;
}
#hasData ul li:hover {
  cursor: pointer;
  padding: 0 30px 0 40px;
  background: #e6e4e4;
  transition: all 0.2s;
}
#hasData ul li:hover span {
  color: #3949ab;
  transition: all 0.2s;
}
#hasData li #title {
  color: #606266;
  font-size: 16px;
  font-weight: 400;
}
#hasData li #date {
  color: #9898a0;
  font-size: 14px;
}
/deep/ #hasData .el-divider--horizontal {
  margin: 20px 0;
  border-bottom: 1px solid #333;
}
/deep/ #hasData .is-center {
  background: #f5f5f5;
  font-size: 18px;
}
</style>