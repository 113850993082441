import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 浏览器大小
    clientWidth: '',
    clientHeight: '',
    // 浏览器高宽监听
    clientSize: {
      width: '0',
      height: '0'
    },
    // 是否显示loading
    showLoading: 0,
    isFullScreen: false, // 是否在全屏
    fullScreenHTML: '', // 全屏图片页面的html
    isMobile: false // 是否为手机端
  },
  mutations: {
    // 是否为移动端
    set_isMobile(state, flag) {
      state.isMobile = flag;
    },
    // 打开或关闭全屏
    set_FULLSCREENFLAG(state, flag) {
      state.isFullScreen = flag;
    },
    // 设置全屏图片页面的html
    set_FULLSCREENIMG(state, item) {
      item.style.height = '';
      state.fullScreenHTML = item.outerHTML;
    },
    // 设置clientHeight
    set_clientHeight(state, clientHeight) {
      this.state.clientHeight = clientHeight
    },
    // 设置clientWidth
    set_clientWidth(state, clientWidth) {
      this.state.clientWidth = clientWidth
    },
    // 设置clientSize
    set_clientSize(state, clientSize) {
      this.state.clientSize = clientSize
    },
    SHOWLOADING(state) {
      state.showLoading = state.showLoading + 1
    },
    HIDELOADING(state) {
      if (state.showLoading > 0) {
        state.showLoading = state.showLoading - 1
      }
    },
    RESETLOADING(state) {
      state.showLoading = 0
    },
  },
  actions: {
    showloader: ({
      commit
    }) => {
      commit('SHOWLOADING')
    },
    hideloader: ({
      commit
    }) => {
      commit('HIDELOADING')
    },
    resetloader: ({
      commit
    }) => {
      commit('RESETLOADING')
    }
  },
  getters: {
    showLoading(state) {
      return state.showLoading === 0
    },
  },
});